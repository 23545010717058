import { useRef, useState } from 'react';
import {
  ComboBox,
  ComboBoxItem,
  type ComboBoxProps,
} from '@breezeai-frontend/cargo-ui';
import { useDebounce } from '@uidotdev/usehooks';
import { v4 as uuid } from 'uuid';

import { type PlaceSuggestion } from '../../../model/Place';
import { usePlaces } from '../../certificates/certificateApiHooks';

export interface PlacesComboBoxProps
  extends Omit<ComboBoxProps<PlaceSuggestion>, 'children'> {}

export function PlacesComboBox({
  onInputChange,
  ...props
}: PlacesComboBoxProps) {
  const [query, setQuery] = useState<string | undefined>();
  const debouncedQuery = useDebounce(query, 500);

  const session_token = useRef(uuid()).current;

  const { data, isFetching } = usePlaces({
    options: {
      useErrorBoundary: true,
      suspense: false,
      refetchOnWindowFocus: false,
      enabled: !!debouncedQuery,
      keepPreviousData: true,
      cacheTime: 0,
    },
    params: {
      query: debouncedQuery,
      session_token,
    },
  });

  return (
    <ComboBox
      items={data?.results}
      onInputChange={(value) => {
        onInputChange && onInputChange(value);
        setQuery(value);
      }}
      isFetchingItems={isFetching}
      allowsEmptyCollection
      {...props}
    >
      {data?.results.map(({ description, place_id }) => (
        <ComboBoxItem
          key={JSON.stringify({ place_id, description, session_token })}
          id={JSON.stringify({ place_id, description, session_token })}
          textValue={description}
        >
          {description}
        </ComboBoxItem>
      ))}
    </ComboBox>
  );
}
