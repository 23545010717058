import { type HTMLAttributes, type ReactNode } from 'react';
import { Typography } from '@breeze-ai/ui-library';

interface SectionLayoutProps
  extends Omit<
    HTMLAttributes<HTMLDivElement>,
    'title' | 'actions' | 'children'
  > {
  title?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
}

export function SectionLayout({
  title,
  actions,
  children,
  ...props
}: SectionLayoutProps) {
  const isDisplayTitle = title || actions;
  return (
    <div
      {...props}
      className="flex flex-grow items-center flex-col space-y-6 h-full relative"
    >
      {isDisplayTitle && (
        <div className="flex-row absolute flex justify-between w-full z-20 pb-14 bg-gradient-to-b from-surfaces-secondary from-30% to-transparent gap-x-3">
          {title && (
            <Typography
              level="h2"
              className="w-full whitespace-nowrap"
              role="heading"
              data-testid="page-title"
            >
              {title}
            </Typography>
          )}
          <div className="flex items-center gap-4 justify-self-end">
            {actions}
          </div>
        </div>
      )}
      <div className="flex w-full h-full overflow-auto no-scrollbar pt-10">
        {children}
      </div>
    </div>
  );
}
