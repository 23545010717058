export type SortOrder = 'asc' | 'desc';

export type PaginationBaseQueryParams<
  SortOption = string,
  FilterableFields = string[],
> = {
  limit?: number;
  page_pointer?: string;
  sort_by?: SortOption;
  order?: SortOrder;
  query?: string;
  paginate?: boolean;
  ids?: (string | number)[];
  filters?: FilterBaseParam<FilterableFields>[];
};

export type PaginationBaseResponse = {
  next_page_pointer: string | null;
  prev_page_pointer: string | null;
  total_num_records: number;
};

export type BaseErrorType = 'GenericException';
export type ErrorResponse<T = BaseErrorType> = Error & {
  response: Response & {
    data: {
      error_type: T | BaseErrorType;
      message?: string | null;
    };
  };
};
export const enum FilterOperators {
  EQUALS = 'equals',
  NOT_EQUALS = 'not_equals',
  BETWEEN = 'between',
  CONTAINS = 'contains',
  CONTAINS_CASE_SENSITIVE = 'contains_case_sensitive',
  GREATER_OR_EQUALS = 'greater_or_equals',
  LESSER_OR_EQUALS = 'lesser_or_equals',
}

export type FilterBaseParam<T> = {
  field: T;
  operator: FilterOperators;
  values: string | string[];
};

export enum FilterableFields {
  CREATED_AT = 'created_at',
  CUSTOMER_ID = 'customer_id',
  CREATED_BY_USER_ID = 'created_by_user_id',
  INVOICE_ID = 'invoice_id',
}
