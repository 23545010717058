import { type ReactNode, useMemo } from 'react';
import { Currency, Typography } from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Quote } from '../../../model/Quote';
import { getFormattedContainerMode } from '../../../network/apis/quotes/helpers';
import { formatCurrencyValue } from '../../../utils/Internationalization';
import { CurrencyExchangeRate } from '../CurrencyExchangeRate/CurrencyExchangeRate';
import styles from './PolicySummary.module.scss';

export type SummaryItem = {
  title: ReactNode;
  value?: ReactNode;
  hidden?: boolean;
  testId?: string;
};

const useSummaryMainItems = ({
  premium_value,
  premium_currency,
  converted_customer_premium_value = 0,
  tax,
  converted_tax_amount = 0,
  exchange_rate,
  deductible_value = 0,
  deductible_currency,
  external_reference,
}: Quote): SummaryItem[][] => {
  const showExchangeRate = useFeatureToggle('show_currencies_exchange_rates');

  return useMemo(
    () => [
      [
        {
          title: (
            <Typography level="h4" data-testid="title">
              Details Summary
            </Typography>
          ),
          value: (
            <Typography level="h5" data-testid="value">
              {external_reference}
            </Typography>
          ),
          testId: 'booking-reference-id',
        },
      ],
      [
        {
          title: (
            <Typography className={styles.subItem} data-testid="title">
              Premium
            </Typography>
          ),
          value:
            premium_value !== undefined && premium_currency ? (
              <Currency
                testId="value"
                value={premium_value}
                currency={premium_currency}
                typographyProps={{ className: styles.subItem }}
              />
            ) : null,
          testId: 'premium',
        },
        ...(tax?.value
          ? [
              {
                title: (
                  <Typography data-testid="title" className={styles.subItem}>
                    Taxes
                  </Typography>
                ),
                value: !tax ? null : (
                  <Currency
                    testId="value"
                    value={tax.value}
                    currency={tax.currency_code}
                    typographyProps={{ className: styles.subItem }}
                  />
                ),
                hidden: !tax,
                testId: 'taxes',
              },
            ]
          : []),
        {
          title: (
            <Typography data-testid="title" className={styles.subItem}>
              Deductible
            </Typography>
          ),
          value:
            !deductible_value || !deductible_currency ? null : (
              <Currency
                testId="value"
                value={deductible_value}
                currency={deductible_currency}
                typographyProps={{ className: styles.subItem }}
              />
            ),
          hidden: !deductible_value,
          testId: 'deductible',
        },
      ],
      [
        {
          title: (
            <Typography level="h5" data-testid="title">
              Total
            </Typography>
          ),
          value:
            !tax || premium_value === undefined || !premium_currency ? null : (
              <Currency
                testId="value"
                value={premium_value + tax.value}
                currency={premium_currency}
                typographyProps={{ level: 'h2' }}
              />
            ),
          hidden: premium_currency !== tax?.currency_code,
          testId: 'total',
        },
        ...(showExchangeRate
          ? [
              {
                title: (
                  <Typography data-testid="title">
                    Total in {exchange_rate?.to_currency.code}
                  </Typography>
                ),
                value: !exchange_rate ? null : (
                  <Currency
                    testId="value"
                    value={
                      converted_customer_premium_value + converted_tax_amount
                    }
                    currency={exchange_rate.to_currency.code}
                  />
                ),
                hidden: !exchange_rate,
                testId: 'converted-total',
              },
              {
                title: !exchange_rate ? null : (
                  <CurrencyExchangeRate
                    {...exchange_rate}
                    typographyProps={{
                      level: 'subtext',
                      className: styles.subItem,
                    }}
                  />
                ),
              },
            ]
          : []),
      ],
    ],
    [
      showExchangeRate,
      converted_customer_premium_value,
      converted_tax_amount,
      deductible_currency,
      deductible_value,
      exchange_rate,
      premium_currency,
      premium_value,
      tax,
      external_reference,
    ],
  );
};

const useSummaryFooterItems = ({
  commodity_category,
  insured_value,
  insured_value_currency,
  container_mode,
}: Quote): SummaryItem[] => {
  return useMemo(
    () => [
      {
        title: 'Insured Value',
        value:
          formatCurrencyValue({
            value: insured_value,
            currency_code: insured_value_currency,
          }) ?? '',
        testId: 'insured-value',
      },
      {
        title: 'Commodity type',
        value: commodity_category,
        testId: 'commodity-type',
      },
      {
        title: 'Load Type',
        value: getFormattedContainerMode(container_mode),
        testId: 'container-mode',
      },
    ],
    [commodity_category, container_mode, insured_value, insured_value_currency],
  );
};

export const usePolicySummaryItems = (quote: Quote) => {
  const mainItems = useSummaryMainItems(quote);
  const footerItems = useSummaryFooterItems(quote);

  return { mainItems, footerItems };
};
