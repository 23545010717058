import { type ComponentProps } from 'react';
import { Icon, Popover, Typography } from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { type PremiumOffer } from '../../../../model/Quote';
import { PremiumBreakdown } from '../PremiumBreakdown';
import styles from './FloatingBreakdownPopover.module.scss';

type PremiumBreakdownPopoverProps = Partial<ComponentProps<typeof Popover>> & {
  entity: PremiumOffer;
};

const defaultAnchorElement = (
  <span role="premium-breakdown-anchor">
    <Icon icon="info-circle" size="sm" className={styles.anchorElement} />
  </span>
);

// TODO(https://breeze.fibery.io/Breeze/Task/Additional-Clauses---Post-release-cleanup-2353)
// Remove this component after Additional Clauses release.
export const FloatingBreakdownPopover = ({
  entity,
  anchorElement = defaultAnchorElement,
  ...props
}: PremiumBreakdownPopoverProps) => {
  const showExchangeRate = useFeatureToggle('show_currencies_exchange_rates');
  const { tax: { value: taxValue = 0 } = {} } = entity;

  if (!taxValue && (!entity.exchange_rate || !showExchangeRate)) {
    return null;
  }

  return (
    <Popover
      anchorElement={anchorElement}
      trigger="hover"
      className={styles.wrapper}
      placement="top-left"
      role="premium-breakdown-tooltip"
      {...props}
    >
      <Typography level="h4" prefixIcon="info-circle" className={styles.header}>
        Price Details
      </Typography>
      <PremiumBreakdown
        entity={entity}
        rowClassname={styles.priceComponentRow}
      />
    </Popover>
  );
};
