import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { SectionLayout } from '@breezeai-frontend/cargo-ui';
import invariant from 'tiny-invariant';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { PlatformErrorBoundary } from '../../../router/PlatformErrorBoundary';
import { useTracking } from '../../../utils/snowplow/hooks';
import { PolicyClaims } from '../../components/PolicyClaims/PolicyClaims';
import { type CertificatesRouteParams } from '../types';
import styles from './CertificateDetailsPage.module.scss';
import { CertificateShipmentDetails } from './CertificateShipmentDetails/CertificateShipmentDetails';
import { NamedAssuredDetails } from './NamedAssuredDetails/NamedAssuredDetails';
import { PollingFileList } from './PollingFileList/PollingFileList';
import Title from './Title/Title';

// TODO Create skeleton loader for this component that inherits width and height from the parent component
export const CertificateDetailsPage = () => {
  useTracking();

  const enableClaims = useFeatureToggle('enable_claims');

  const { policyId } = useParams<CertificatesRouteParams>();
  invariant(policyId, 'policyId is required');

  return (
    <PlatformErrorBoundary>
      <SectionLayout data-testid="certificate-details-page" title={<Title />}>
        <div className="flex flex-col gap-6 w-full">
          <div className="w-full flex flex-row gap-6">
            <Suspense
              fallback={
                <div
                  style={{ height: '330px', width: '100%' }}
                  data-testid="skeleton-loader"
                  className={styles.skeletonLoader}
                />
              }
            >
              <CertificateShipmentDetails policyId={policyId} />
            </Suspense>
          </div>
          <div className="w-full flex flex-row gap-6">
            <Suspense
              fallback={
                <div
                  style={{ height: '330px', width: '100%' }}
                  data-testid="skeleton-loader"
                  className={styles.skeletonLoader}
                />
              }
            >
              <NamedAssuredDetails policyId={policyId} />
            </Suspense>
          </div>
          <Suspense
            fallback={
              <div
                style={{ height: '200px' }}
                className={styles.skeletonLoader}
              />
            }
          >
            <PollingFileList policyId={policyId} />
          </Suspense>

          {enableClaims && (
            <Suspense
              fallback={
                <div
                  style={{ height: '100px' }}
                  className={styles.skeletonLoader}
                />
              }
            >
              <div className="p-6 rounded-3xl bg-system-grey-white">
                <PolicyClaims policyId={policyId} />
              </div>
            </Suspense>
          )}
        </div>
      </SectionLayout>
    </PlatformErrorBoundary>
  );
};
