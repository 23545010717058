import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useTracking } from '../../utils/snowplow/hooks';
import { QuotesTable } from './components/QuotesTable/QuotesTable';

export const QuotesPage = () => {
  useTracking();

  return (
    <SectionLayout
      title="Quotes"
      data-testid="quotes-page"
      actions={
        <LinkButton
          size="small"
          data-testid="get-quote-button"
          href="/quotes/create"
          width="auto"
          label="Get A Quote"
        />
      }
    >
      <QuotesTable />
    </SectionLayout>
  );
};
