import { create } from 'zustand';

import {
  type FilterableFields,
  type FilterBaseParam,
} from '../../../network/apis/types';

interface ParamStore<T> {
  filters: FilterBaseParam<T>[];
  updateFilter: (filter: FilterBaseParam<T>) => void;
  removeFilter: (field: T) => void;
  clearFilters: () => void;

  selectedTime: undefined | string;
  setSelectedTime: (selectedTimeRange?: string) => void;

  selectedUsers: string[];
  setSelectedUsers: (selectedUsers: string[]) => void;

  selectedCargoOwners: string[];
  setSelectedCargoOwners: (selectedCargoOwners: string[]) => void;

  customDateRange: {
    startDate?: string;
    endDate?: string;
  };
  setCustomDateRange: (customDateRange: {
    startDate?: string;
    endDate?: string;
  }) => void;
}

// TODO: Unable to make this generic. Filterable fields are different for each page and it should be passed as a generic type.
// But it is not possible to pass generic type to zustand store in an elegant manner.
// This may lead to a lack of type safety in the future where we may pass wrong filterable fields to the store. They are currently all the same.
export const useFilterStore = create<ParamStore<FilterableFields>>()(
  (set): ParamStore<FilterableFields> => ({
    filters: [],

    updateFilter: (filter) =>
      set((state) => {
        // Remove filter if no values
        if (filter.values === undefined || filter.values.length === 0) {
          return {
            filters: state.filters.filter(
              (filterItem) => filterItem.field !== filter.field,
            ),
          };
        }
        // Upsert filter
        return {
          filters: [
            ...state.filters.filter(
              (filterItem) => filterItem.field !== filter.field,
            ),
            filter,
          ],
        };
      }),
    removeFilter: (field) =>
      set((state) => ({
        filters: state.filters.filter((filter) => filter.field !== field),
      })),
    clearFilters: () =>
      set({
        filters: [],
        selectedTime: undefined,
        selectedUsers: [],
        selectedCargoOwners: [],
      }),
    selectedTime: undefined,
    setSelectedTime: (selectedTime) => set({ selectedTime }),

    selectedUsers: [],
    setSelectedUsers: (selectedUsers) => set({ selectedUsers }),

    selectedCargoOwners: [],
    setSelectedCargoOwners: (selectedCargoOwners) =>
      set({ selectedCargoOwners }),

    customDateRange: { startDate: undefined, endDate: undefined },
    setCustomDateRange: (customDateRange) => set({ customDateRange }),
  }),
);
