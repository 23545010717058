import { Badge, Typography } from '@breeze-ai/ui-library';

import { usePolicy } from '../../../../network/apis/policies/hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { useAppNavigation } from '../../../../router/use-app-navigation';
import { type PolicyRouteParams } from '../PolicyDetailsPage';
import styles from './Title.module.scss';

export default function Title() {
  const { navigateToSection } = useAppNavigation();
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const { data: policy, isLoading } = usePolicy(
    {
      policyId,
    },
    {
      suspense: false,
    },
  );
  const certificate_id = policy?.certificate_id;
  const { certificate_status, proof_of_cover_status } = policy ?? {};

  return (
    <div className={styles.title}>
      <Typography
        level="h2"
        prefixIcon="chevron-left"
        clickable
        onClick={() => navigateToSection('policies')}
      />
      {certificate_id && (
        <Typography level="h2">Policy {certificate_id}</Typography>
      )}
      <div className={styles.badge}>
        {!isLoading && !certificate_status && !proof_of_cover_status && (
          <Badge
            size="large"
            variant="default"
            data-testid="documents-pending-badge"
          >
            Pending Cargo Owner Approval
          </Badge>
        )}
      </div>
    </div>
  );
}
