import {
  ChartTooltip,
  Currency,
  formatDate,
  normalizeDate,
  Typography,
} from '@breeze-ai/ui-library';
import moment from 'moment';

import { useFeatureToggle } from '../../../../../context/auth/auth-hooks';
import { type PoliciesMonthlyInsights } from '../../../../../model/Insights';
import { staticDateFormats } from '../../../../../utils/dates';
import { getAppLocale } from '../../../../../utils/locale';
import styles from './GraphWidgetTooltip.module.scss';

type PoliciesWidgetTooltipProps = {
  data: PoliciesMonthlyInsights;
};

export const GraphWidgetTooltip = ({
  data: { month, total, total_insured },
}: PoliciesWidgetTooltipProps) => {
  const isCertificatesEnabled = useFeatureToggle('enable_certificates_page');

  const locale = getAppLocale();
  const date = normalizeDate(moment({ month: month - 1 }));
  const formattedMonth = formatDate(date, {
    locale,
    customFormat: staticDateFormats.FULL_MONTH,
  });

  return (
    <ChartTooltip className={styles.tooltip}>
      <Typography bold variant="primary" className={styles.title}>
        {formattedMonth}
      </Typography>
      <div className={styles.tooltipItemRow}>
        <Typography>
          {isCertificatesEnabled ? 'Certificates:' : 'Policies:'}
        </Typography>
        <Typography bold>{total}</Typography>
      </div>
      <div className={styles.tooltipItemRow}>
        <Typography>Cargo Value Insured:</Typography>
        <Currency
          typographyProps={{ bold: true }}
          value={total_insured.value}
          currency={total_insured.currency_code}
        />
      </div>
    </ChartTooltip>
  );
};
