import { useEffect, useRef } from 'react';
import { PiSpinnerBold } from 'react-icons/pi';
import { Typography } from '@breezeai-frontend/cargo-ui';

import { type Quote } from '../../../../model/Quote';
import { ExclusionReasons } from '../../../components/ExclusionReasons/ExclusionReasons';

export function CertificateReviewCard({
  exclusionReasons,
}: {
  exclusionReasons?: Quote['exclusion_reasons'];
}) {
  // Scroll into view
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center gap-6 rounded-3xl p-3 bg-white w-full py-56 mt-12"
      ref={ref}
    >
      <PiSpinnerBold className="fill-icons-primary" size={50} />
      <Typography level="h2" customStyles="text-center">
        The certificate needs to be processed manually, due to some exceptions.{' '}
      </Typography>
      <Typography level="base" customStyles="text-center">
        We will get back to you by email as soon as possible.
      </Typography>

      {exclusionReasons?.length && (
        <div
          data-testid="exclusion-reasons-section"
          className="w-full mt-6 max-w-[670px] px-5"
        >
          <div className="h-[1px] w-full bg-system-grey-400 mb-6" />
          <ExclusionReasons reasons={exclusionReasons} />
        </div>
      )}
    </div>
  );
}
