import { SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useTracking } from '../../utils/snowplow/hooks';
import { PoliciesTable } from './PoliciesTable/PoliciesTable';

export const PoliciesPage = () => {
  useTracking();

  return (
    <SectionLayout title="Policies" data-testid="policies-page">
      <PoliciesTable />
    </SectionLayout>
  );
};
