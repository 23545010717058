import { useRef } from 'react';
import {
  Button,
  ComboBox,
  type ComboBoxProps as AriaComboBoxProps,
  composeRenderProps,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  type ListBoxItemProps,
} from 'react-aria-components';
import { type IconType } from 'react-icons';
import { FaExclamationCircle } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';

import { tv } from '../../utils/customTv';
import { clsxMerge } from '../common/utils/classNameUtils';
import { fieldErrorStyles, fieldStyles } from '../Field/Field';
import { IconBadge } from '../IconBadge/IconBadge';
import { Popover } from '../Popover/Popover';
import { Spinner } from '../Spinner/Spinner';
import { Typography } from '../Typography/Typography';

/**
 * Had to copy over the border styles from /components/Field/Field.tsx.
 * For some reason, extending the fieldBorderStyles here breaks Storybook.
 *
 * TODO: understand why and fix
 */
const comboBoxStyles = tv({
  base: 'flex flex-col rounded-lg p-4 pt-3 cursor-text bg-system-grey-white border border-field-border-default focus:border-field-border-focused',
  variants: {
    isInvalid: {
      true: 'border-field-border-error',
    },
    isDisabled: {
      true: 'border-field-border-disabled',
    },
  },
});

const iconStyles = tv({
  base: 'text-system-grey-600',
  variants: {
    filled: {
      true: 'text-icons-primary',
    },
  },
});

export interface LocationComboBoxProps<T extends object>
  extends Omit<AriaComboBoxProps<T>, 'children'> {
  label: string;
  placeholder?: string;
  children?: React.ReactNode | ((item: T) => React.ReactNode);
  isFetchingItems?: boolean;
  emptyCollectionMessage?: string;
  errorMessage?: string;
  customStyles?: string;
  offsetErrorMessage?: number;
  SelectionIcon?: IconType;
}

export function LocationComboBox<T extends object>({
  label,
  children,
  items,
  placeholder = 'Add location',
  emptyCollectionMessage = 'No results',
  isFetchingItems = false,
  isRequired = false,
  isInvalid = false,
  errorMessage,
  offsetErrorMessage,
  SelectionIcon,
  ...props
}: LocationComboBoxProps<T>) {
  const labelRef = useRef<HTMLLabelElement>(null);

  const Icon = SelectionIcon || FaLocationDot;

  return (
    <Label
      ref={labelRef}
      className={clsxMerge(fieldStyles({ isInvalid }), 'inline-block w-full')}
    >
      <ComboBox
        {...props}
        aria-label={label}
        items={items}
        allowsEmptyCollection
        isRequired={isRequired}
        isInvalid={isInvalid}
        className={composeRenderProps(
          props.className,
          (className, renderProps) =>
            comboBoxStyles({ ...renderProps, className }),
        )}
      >
        <div className="flex gap-1 text-sm font-normal text-field-text-labels leading-4 mb-2 w-fit">
          {label}
          {isRequired && (
            <span data-testid="required-symbol" className="text-system-red-500">
              *
            </span>
          )}
        </div>
        <div className="flex items-center gap-2">
          <span data-testid="input-icon">
            <Icon className={iconStyles({ filled: Boolean(SelectionIcon) })} />
          </span>
          <Input
            role="input"
            placeholder={placeholder}
            className="outline-none h-5 w-full overflow-ellipsis flex-grow text-base font-normal"
          />
          {isFetchingItems && (
            <span>
              <Spinner className="w-4 h-4" />
            </span>
          )}
          <Button />
        </div>
        <Popover
          triggerRef={labelRef}
          className="flex flex-col overflow-hidden w-[calc(var(--trigger-width)+9.5rem)]"
        >
          <ListBox
            className="p-2 overflow-auto max-h-96"
            items={items}
            renderEmptyState={() => (
              <Typography customStyles="p-3">
                {emptyCollectionMessage}
              </Typography>
            )}
          >
            {children}
          </ListBox>
        </Popover>
      </ComboBox>
      {/*
          We cannot use  FieldError here because this is used outside of the ComboBox.
          The LocationComboBox has custom styles compared to other fields, so this is a
          one time thing.
      */}
      {errorMessage && (
        <div
          className={clsxMerge(
            fieldErrorStyles({ absolute: true }),
            'z-10',
            offsetErrorMessage ? `left-2.5` : undefined,
          )}
        >
          <span className="bg-white rounded-full">
            <FaExclamationCircle className="text-red-500" size={12} />
          </span>
          {errorMessage}
        </div>
      )}
    </Label>
  );
}

const itemStyles = tv({
  base: 'p-2 flex gap-5 items-center rounded-lg text-base font-normal',
  variants: {
    highlighted: {
      true: 'bg-system-grey-50 cursor-pointer',
    },
  },
});

interface LocationItemProps extends Omit<ListBoxItemProps, 'children'> {
  children: React.ReactNode;
  Icon?: IconType;
}

export function LocationItem({ children, Icon, ...props }: LocationItemProps) {
  return (
    <ListBoxItem {...props}>
      {(item) => {
        const { isHovered, isFocused } = item;

        const isHighlighted = isHovered || isFocused;

        return (
          <div className={itemStyles({ highlighted: isHighlighted })}>
            {Icon && (
              <IconBadge
                data-testid="location-item-icon"
                variant={isHighlighted ? 'tertiary' : 'secondary'}
                customStyles="min-w-8 min-h-8 w-8 h-8"
              >
                <Icon />
              </IconBadge>
            )}
            {children}
          </div>
        );
      }}
    </ListBoxItem>
  );
}
