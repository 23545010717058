import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../../../../context/auth/auth-hooks';
import { type Distributor } from '../../../../../model/Distributor';

export function DistributorSelect(
  props: Omit<SelectProps<Distributor>, 'children'>,
) {
  const user = useAuthenticatedUser();

  const distributors = [
    user.distributor,
    ...(user.distributor?.children ?? []),
  ];

  return (
    <Select validationBehavior="aria" label="Freight Forwarder" {...props}>
      {distributors.map(({ legal_name, id }) => (
        <SelectItem key={id} id={id} textValue={legal_name}>
          {legal_name}
        </SelectItem>
      ))}
    </Select>
  );
}
