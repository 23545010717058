import { clsxMerge } from '../../components/common/utils/classNameUtils';
import { Typography } from '../../components/Typography/Typography';

interface TotalPriceBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  price: string;
  priceBreakdownTooltip?: React.ReactNode;
}

export function TotalPriceBanner({
  price,
  priceBreakdownTooltip,
  className,
  ...props
}: TotalPriceBannerProps) {
  return (
    <div
      className={clsxMerge(
        'w-full flex space-between bg-system-grey-50 rounded-3xl py-9 px-6 justify-between items-center min-w-64',
        className,
      )}
      {...props}
    >
      <div className="flex gap-3 items-center">
        <Typography customStyles="text-[22px] font-bold leading-5 text-system-grey-850 whitespace-nowrap">
          Total price
        </Typography>
        {priceBreakdownTooltip}
      </div>
      <Typography customStyles="text-[41px] font-bold leading-8 text-text-brand-color">
        {price}
      </Typography>
    </div>
  );
}
