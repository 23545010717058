import { type ComponentProps, useCallback, useRef } from 'react';
import { Autocomplete, Typography } from '@breeze-ai/ui-library';

import googleLogo from '../../../assets/google_logo.png';
import { isPlace, type Place } from '../../../model/Place';
import {
  getPlaceDetails,
  getPlaceSuggestions,
} from '../../../network/apis/places/places';
import styles from './PlaceAutocomplete.module.scss';

type PlaceAutocompleteProps = Omit<
  ComponentProps<typeof Autocomplete<Place>>,
  'getOptionValue' | 'defaultValue' | 'onChange'
> & {
  authApi?: boolean;
  onChange: (place: Place) => void;
  defaultValue?: Place | string;
  showPlacesAttribution?: boolean;
};

const DEBOUNCE_MS = 1000;
const defaultLabel = 'Place';
const defaultPlaceholder = 'Type to search a place...';

const getDisplayablePlace = (address: string): Place => ({
  name: address,
  address: { full_address: address },
  provider: '',
  provider_place_uuid: '',
});

const PlacesAttribution = () => (
  <div className={styles.attribution} role="attribution">
    <Typography level="subtext">powered by</Typography>
    <img src={googleLogo} alt="Google logo" />
  </div>
);

export const PlaceAutocomplete = ({
  value,
  defaultValue,
  onChange,
  inputProps,
  showPlacesAttribution = false,
  authApi = true,
  ...props
}: PlaceAutocompleteProps) => {
  const sessionToken = useRef<string>();
  let displayedDefaultValue;

  if (defaultValue) {
    displayedDefaultValue = isPlace(defaultValue)
      ? defaultValue
      : getDisplayablePlace(defaultValue);
  }

  const getSuggestions = useCallback(
    async (query?: string): Promise<Place[]> => {
      const { suggestions, session_token } = await getPlaceSuggestions(
        { query_text: query, session_token: sessionToken.current },
        authApi,
      );

      if (!sessionToken.current) {
        sessionToken.current = session_token;
      }

      return suggestions;
    },
    [authApi],
  );

  const handleSelect = useCallback(
    async ({ provider_place_uuid }: Place) => {
      const place = await getPlaceDetails(
        {
          provider_place_id: provider_place_uuid,
          session_token: sessionToken.current,
        },
        authApi,
      );

      sessionToken.current = undefined;
      onChange(place);
    },
    [authApi, onChange],
  );

  return (
    <Autocomplete<Place>
      value={value}
      defaultValue={displayedDefaultValue}
      fetchOptions={getSuggestions}
      debounceMs={DEBOUNCE_MS}
      getOptionValue={(option) => option.provider_place_uuid}
      onChange={handleSelect}
      getOptionLabel={({ name, address }) => address?.full_address ?? name}
      isOptionEqualToValue={(option, value) =>
        option.provider_place_uuid === value.provider_place_uuid
      }
      blurOnSelect={true}
      disableClearable={true}
      inputProps={{
        ...inputProps,
        type: 'text',
        required: inputProps?.required ?? true,
        label: inputProps?.label ?? defaultLabel,
        placeholder: inputProps?.placeholder ?? defaultPlaceholder,
      }}
      dropdownFooter={showPlacesAttribution ? <PlacesAttribution /> : undefined}
      {...props}
    />
  );
};
