import { Typography } from '@breezeai-frontend/cargo-ui';
import DOMPurify from 'dompurify';

import { removeInlineStyles } from '../../../../../../../packages/cargo-ui/src/utils/tools';
import { usePolicy } from '../../../../network/apis/policies/hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { DeductibleMessage } from '../../../components/DeductibleMessage/DeductibleMessage';
import { PageSection } from '../../../components/PageSection/PageSection';
import { type PolicyRouteParams } from '../PolicyDetailsPage';

export function PolicyAdditionalClauses() {
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;

  const { data: policy } = usePolicy({ policyId });

  const additionalClause = policy?.quote?.quote_additional_clauses?.[0];

  if (!additionalClause) {
    return null;
  }

  const { clause_text, ...deductibleProps } = additionalClause;
  const cleanTextMarkup = DOMPurify.sanitize(clause_text);

  return (
    <PageSection>
      <div className="flex flex-col gap-1.5">
        <Typography level="h3">Additional clauses</Typography>
        <DeductibleMessage {...deductibleProps} />
        <div
          dangerouslySetInnerHTML={{
            __html: removeInlineStyles(cleanTextMarkup),
          }}
          className="text-text-secondary text-sm leading-3 text-left"
        />
      </div>
    </PageSection>
  );
}
