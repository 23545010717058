import { type ComponentProps, type ReactElement, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorMessage } from '@breeze-ai/ui-library';
import { Spinner } from '@breezeai-frontend/cargo-ui';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import classnames from 'classnames';

import { useAuthActions } from '../../context/auth/auth-hooks';
import styles from './PlatformErrorBoundary.module.scss';

type PlatformErrorProps = ComponentProps<typeof ErrorMessage> & {
  className?: string;
  suspenseFallback?: ReactElement;
  errorFallback?: ReactElement;
};

export const PlatformErrorBoundary = ({
  className,
  suspenseFallback = <Spinner className="self-center" />,
  ...props
}: PlatformErrorProps) => {
  const { logout } = useAuthActions();

  return (
    <div className={classnames(styles.platformError, className)}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => (
              <div data-testid="platform-error" className={styles.defaultError}>
                <ErrorMessage
                  actions={[
                    {
                      level: 'secondary',
                      children: 'Refresh',
                      prefixIcon: 'refresh',
                      onClick: resetErrorBoundary,
                    },
                    {
                      ghost: true,
                      children: 'Logout',
                      prefixIcon: 'sign-out',
                      variant: 'error',
                      onClick: logout,
                    },
                  ]}
                  {...props}
                />
              </div>
            )}
          >
            <Suspense fallback={suspenseFallback}>{props.children}</Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </div>
  );
};
