import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { type GridRowParams } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import {
  type FilterableFields,
  type PaginationBaseQueryParams,
  type PaginationBaseResponse,
} from '../../../../network/apis/types';
import { post } from '../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../network/netconfig';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { useFilterStore } from '../../../components/TableFilters';
import {
  type StorageLocationModel,
  type StorageLocationsSortableFields,
  StorageLocationStatusEnum,
} from '../../types';
import { useStorageLocationsTableColumns } from './useStorageLocationsTableColumns';

export interface StorageLocationsResponse extends PaginationBaseResponse {
  locations: StorageLocationModel[];
}

const getActiveLocationsCount = (locations: StorageLocationModel[]) =>
  locations.filter(({ status }) => status === StorageLocationStatusEnum.ACTIVE)
    .length;

// The table component is non compatible with react-query
export const getStorageLocations = async (
  params?: PaginationBaseQueryParams<
    StorageLocationsSortableFields,
    FilterableFields
  >,
): Promise<StorageLocationsResponse> => {
  const { data } = await post<StorageLocationsResponse>(
    `${bffServiceUrl}/bff-storage-locations/get`,
    params,
  );

  return {
    ...data,
    locations: data.locations.filter(
      ({ status }) => status !== StorageLocationStatusEnum.DELETED,
    ),
  };
};

export function StorageTable() {
  const DEFAULT_QUERY_PARAMS = {
    limit: 10,
  };
  const enableFilters = useFeatureToggle('enable_column_filters');

  const { filters, clearFilters } = useFilterStore((state) => ({
    filters: state.filters,
    clearFilters: state.clearFilters,
  }));

  const [queryParams, setQueryParams] =
    useState<BaseQueryParams>(DEFAULT_QUERY_PARAMS);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [key, setKey] = useState<number>(0);
  const columns = useStorageLocationsTableColumns({
    refreshTable: () => setKey((prev) => prev + 1),
  });
  const navigate = useNavigate();

  const fetchStorageLocationsPage = useCallback(
    async (
      params: BaseQueryParams,
    ): Promise<PageState<StorageLocationModel>> => {
      return getStorageLocations({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        //TODO This type cast is necessary because the type of sortBy is string | undefined. Would be nice to fix this in the table component.
        sort_by: params?.sortBy as StorageLocationsSortableFields,
        page_pointer: params?.pagePointer,
        filters,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.locations,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: getActiveLocationsCount(page.locations),
          };
        })
        .catch((e) => {
          reportException(e);
          return { records: [] };
        });
    },
    [filters],
  );

  // clear filter when navigating away from page
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  return (
    <div className="w-full h-full">
      <ServerSideTableV2<StorageLocationModel>
        key={key}
        testId="storage-table"
        fetchPage={fetchStorageLocationsPage}
        columns={columns}
        getRowId={(row) => row.id}
        onRowClick={({ row }: GridRowParams<StorageLocationModel>) => {
          navigate(`/storage/details/${row.location_id}/${row.id}`);
        }}
        actions={{ search: true, pageSize: true, filters: enableFilters }}
        searchProps={{
          placeholder: 'Search Location Name or ID',
        }}
        setCurrentPageNumber={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        autoHeight={false}
        filters={
          <ErrorBoundary fallbackRender={() => <></>}>
            <div className="flex flex-row gap-2 items-end justify-start flex-wrap "></div>
          </ErrorBoundary>
        }
      />
    </div>
  );
}
