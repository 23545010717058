import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { type GridRowParams } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Claim } from '../../../model/Claim';
import { getClaims } from '../../../network/apis/claims/claims';
import { type ClaimsSortableFields } from '../../../network/apis/claims/types';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { getTheme } from '../../../utils/getTheme';
import { useSectionError } from '../../components/Section/SectionContext';
import {
  CargoOwnersFilter,
  CreatedByFilter,
  CreatedOnFilter,
  useFilterStore,
} from '../../components/TableFilters';
import styles from './ClaimsTable.module.scss';
import { useClaimsTableColumns } from './use-claims-table-columns';

const DEFAULT_QUERY_PARAMS = {
  limit: 10,
};

const isWtw = getTheme() === 'wtw';

export const ClaimsTable = () => {
  const enableFilters = useFeatureToggle('enable_column_filters');
  const { filters, clearFilters } = useFilterStore((state) => ({
    filters: state.filters,
    clearFilters: state.clearFilters,
  }));
  const { navigateToClaim } = useAppNavigation();
  const columns = useClaimsTableColumns();
  const { setError } = useSectionError();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [queryParams, setQueryParams] =
    useState<BaseQueryParams>(DEFAULT_QUERY_PARAMS);

  // clear filter when navigating away from page
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  const fetchClaimsPage = useCallback(
    async (params: BaseQueryParams): Promise<PageState<Claim>> => {
      return getClaims({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as ClaimsSortableFields,
        page_pointer: params?.pagePointer,
        filters,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.claims,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          setError(e);
          return { records: [] };
        });
    },
    [filters, setError],
  );

  return (
    <div className="h-full w-full">
      <ServerSideTableV2<Claim>
        testId="claims-table"
        fetchPage={fetchClaimsPage}
        columns={columns}
        getRowId={(row) => row.id}
        getRowClassName={() => styles.quoteRow}
        onRowClick={({ row }: GridRowParams<Claim>) =>
          navigateToClaim(`${row.id}`)
        }
        actions={{ search: true, pageSize: true, filters: true }}
        searchProps={{ placeholder: 'Search Claim Number' }}
        setCurrentPageNumber={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        autoHeight={false}
        filters={
          enableFilters ? (
            <ErrorBoundary fallbackRender={() => <></>}>
              <div className={styles.filters}>
                <CreatedOnFilter />
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CreatedByFilter />
                </ErrorBoundary>
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CargoOwnersFilter
                    label={isWtw ? 'Named Assured' : 'Cargo owner'}
                  />
                </ErrorBoundary>
                <Button
                  onPress={clearFilters}
                  variant="ghost"
                  size="small"
                  isDisabled={!filters.length}
                  width="fixed"
                  label="Clear filters"
                />
              </div>
            </ErrorBoundary>
          ) : undefined
        }
      />
    </div>
  );
};
