import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocale } from '@breeze-ai/ui-library';
import { CargoProvider } from '@breezeai-frontend/cargo-ui';

import { Footer } from '../../../../../packages/cargo-ui/src/components/Footer/Footer';
import { AppConfigurationProvider } from '../../context/app-configuration/AppConfigurationProvider';
import { useUserSetting } from '../../context/auth/auth-hooks';
import { Sidebar } from '../../ui/sidebar/Sidebar';
import { SupportDialog } from '../../ui/support-dialog/SupportDialog';
import { getTheme } from '../../utils/getTheme';
import { initialLocale, setAppLocale } from '../../utils/locale';

const PRIVACY_POLICY_URL = import.meta.env.VITE_APP_PRIVACY_POLICY_URL;
const TERMS_OF_USE_URL = import.meta.env.VITE_APP_TERMS_OF_USE_URL;

export const AuthorizedPlatform = () => {
  const userLocale = useUserSetting<string>('locale');
  const { setLocale: setComponentsLocale } = useLocale();
  const navigate = useNavigate();
  const theme = getTheme();

  // Update the locale according to the
  // logged-in user and reset it on log out.
  useEffect(() => {
    setAppLocale(userLocale);
    setComponentsLocale?.(userLocale);

    return () => {
      setAppLocale(initialLocale);
      setComponentsLocale?.(initialLocale);
    };
  }, [setComponentsLocale, userLocale]);

  const footerLinks = [
    {
      label: 'Privacy Policy',
      url: PRIVACY_POLICY_URL,
    },
    {
      label: theme === 'wtw' ? 'Cookie Notice' : 'Terms of Use',
      url: TERMS_OF_USE_URL,
    },
  ];

  return (
    <CargoProvider
      theme={theme}
      navigate={navigate}
      userLocaleOverride={userLocale}
    >
      <AppConfigurationProvider>
        <div className="flex h-dvh w-dvw bg-surfaces-secondary">
          <Sidebar />
          <div
            id="main-breeze-app-frame"
            className="w-full min-w-[400px] px-10 flex flex-col justify-between relative overflow-hidden"
          >
            <div className="overflow-auto h-full no-scrollbar pt-10">
              <Outlet />
            </div>
            <Footer links={footerLinks} />
          </div>
        </div>
        <SupportDialog />
      </AppConfigurationProvider>
    </CargoProvider>
  );
};
