/* eslint-disable react/no-children-prop */
import { type HTMLProps, useEffect } from 'react';
import { FaPlus, FaXmark } from 'react-icons/fa6';
import { VscDebugRestart } from 'react-icons/vsc';
import { Button, clsxMerge, Typography } from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { type ConveyanceType } from '../../../model/Shipment';
import {
  type Location,
  type PlaceLocation,
} from '../../../network/apis/locations/types';
import { useFormLogic } from '../../certificates/CertificateFlowPage/useFormLogic';
import { ModeOfTransportSelect } from './ModeOfTransportSelect';
import {
  type ComboBoxKey,
  PortsAndPlacesComboBox,
} from './PortsAndPlacesComboBox';
import { type RouteDetailsFormProps } from './types';
import { useRouteDetailsLogic } from './useRouteDetailsLogic';
import { getPlaceholderText, getSelectionIcon } from './utils';

type EventListeners = {
  primaryMot: {
    onChange?: (value: ConveyanceType) => void;
  };
};

export interface RouteDetailsProps {
  form: FormApi<RouteDetailsFormProps, typeof zodValidator>;
  listeners?: Partial<EventListeners>;
  isUpdate?: boolean;
  isDuplicate?: boolean;
}

const Dash = ({ className, ...props }: HTMLProps<HTMLDivElement>) => (
  <div
    className={clsxMerge(
      'border-l-2 border-dashed border-l-field-border-default h-7 ml-[15px] absolute',
      className,
    )}
    {...props}
  />
);

export function RouteDetails({
  form,
  listeners,
  isUpdate,
  isDuplicate,
}: RouteDetailsProps) {
  const { locationHandleBlur } = useFormLogic();

  const {
    primaryMot,
    showSecondaryTransport,
    setShowSecondaryTransport,
    showPlaceOfLoading,
    setShowPlaceOfLoading,
    showPlaceOfDelivery,
    setShowPlaceOfDelivery,
    originInputValue,
    destinationInputValue,
    placeOfLoadingInputValue,
    placeOfDeliveryInputValue,
    setOriginInputValue,
    setDestinationInputValue,
    setPlaceOfLoadingInputValue,
    setPlaceOfDeliveryInputValue,
    resetRouteDetails,
    handleCloseSecondary,
    handleCloseSecondaries,
    isRouteDetailsDirty,
  } = useRouteDetailsLogic(form);

  useEffect(() => {
    if (primaryMot === 'sea' || primaryMot === 'air') {
      setShowSecondaryTransport(true);
    } else {
      handleCloseSecondaries();
    }
  }, [primaryMot, handleCloseSecondaries, setShowSecondaryTransport]);

  return (
    <div data-testid="route-details" className="w-full">
      <div className="flex flex-row justify-between items-center pb-5 w-full">
        <Typography level="h3">Route Details</Typography>
        {isRouteDetailsDirty && (
          <Button
            data-testid="reset-route-details-button"
            leftIcon={<VscDebugRestart className="size-4" />}
            variant="ghost"
            customStyles="px-2 gap-1"
            onPress={resetRouteDetails}
            label="Reset Route Details"
          />
        )}
      </div>
      <div className="w-full">
        <div className="flex flex-col gap-7">
          {showSecondaryTransport && !showPlaceOfLoading && (
            <div className="relative">
              <Button
                data-testid="add-place-of-loading-button"
                variant="secondary"
                width="square"
                onPress={() => setShowPlaceOfLoading(true)}
                customStyles="w-fit"
              >
                <div className="flex items-center p-2 gap-1">
                  <FaPlus /> <span>Cover door to port - Add loading place</span>
                </div>
              </Button>
              <Dash />
            </div>
          )}
          <div
            data-testid="place-of-loading-section"
            className={clsxMerge(
              'flex flex-col gap-7',
              (!showSecondaryTransport || !showPlaceOfLoading) && 'hidden',
            )}
          >
            <div className="flex items-center gap-x-2 w-full">
              <div className="relative grow">
                <form.Field
                  validators={{
                    onSubmit: ({ value }) => {
                      if (!value && showPlaceOfLoading) {
                        return 'Please select a place of loading';
                      }
                      return;
                    },
                  }}
                  name="placeOfLoading"
                  children={(field) => {
                    const value = field.getValue();
                    const mot = field.form.getFieldValue('placeOfLoadingMot');

                    const SelectionIcon = value
                      ? getSelectionIcon(value, mot)
                      : undefined;
                    return (
                      <PortsAndPlacesComboBox
                        data-testid="place-of-loading-combobox"
                        label="Place of loading"
                        validationBehavior="aria"
                        id={field.name}
                        name={field.name}
                        onBlur={(e) =>
                          locationHandleBlur(
                            e,
                            field,
                            setPlaceOfLoadingInputValue,
                          )
                        }
                        filterByMot={mot ?? undefined}
                        inputValue={placeOfLoadingInputValue ?? ''}
                        onInputChange={(value) => {
                          setPlaceOfLoadingInputValue(value);
                          return;
                        }}
                        onSelectionChange={(key: ComboBoxKey) => {
                          if (key) {
                            const location: PlaceLocation = JSON.parse(
                              String(key),
                            );
                            field.handleChange(location);

                            field.form.setFieldValue(
                              'placeOfLoadingMot',
                              'road',
                            );
                            const meta =
                              field.form.getFieldMeta('placeOfLoadingMot');
                            if (!meta) return;
                            field.form.setFieldMeta('placeOfLoadingMot', {
                              ...meta,
                              isDirty: true,
                            });
                          }
                        }}
                        SelectionIcon={SelectionIcon}
                        offsetErrorMessage={2}
                        isInvalid={field.state.meta.errors.length > 0}
                        errorMessage={field.state.meta.errors[0] || undefined}
                      />
                    );
                  }}
                />
                <Dash />
              </div>
              <Button
                variant="ghost"
                onPress={() => handleCloseSecondary('loading')}
                data-testid="close-place-of-loading-button"
              >
                <FaXmark className="text-system-grey-600 " />
              </Button>
            </div>
            <div className="w-[140px] relative">
              <form.Field
                name="placeOfLoadingMot"
                children={(field) => (
                  <ModeOfTransportSelect
                    data-testid="loading-transport-mode-select"
                    id={field.name}
                    name={field.name}
                    type="secondary"
                    selectedKey={field.getValue() ?? null}
                    onSelectionChange={(value) => {
                      field.handleChange(value);

                      const placeOfLoading =
                        field.form.getFieldValue('placeOfLoading');
                      if (placeOfLoading) {
                        // Not sure how else to trigger a re-render of the field
                        field.form.setFieldValue('placeOfLoading', {
                          ...placeOfLoading,
                        });
                      }
                    }}
                  />
                )}
              />
              <Dash />
            </div>
          </div>
          <div className="relative pr-9">
            <form.Field
              name="origin"
              validators={{
                onBlur: ({ value }) => {
                  if (!value) {
                    return 'Please select an origin';
                  }
                  return;
                },
              }}
              children={(field) => {
                const value = field.getValue();
                const mot = field.form.getFieldValue('primaryMot');

                const SelectionIcon = value
                  ? getSelectionIcon(value, mot)
                  : undefined;

                return (
                  <PortsAndPlacesComboBox
                    data-testid="origin-combobox"
                    label="Origin"
                    validationBehavior="aria"
                    placeholder={mot ? getPlaceholderText(mot) : undefined}
                    isRequired
                    id={field.name}
                    name={field.name}
                    onBlur={(e) =>
                      locationHandleBlur(e, field, setOriginInputValue)
                    }
                    inputValue={originInputValue}
                    onInputChange={(value) => {
                      setOriginInputValue(value);
                      return;
                    }}
                    onSelectionChange={(key: ComboBoxKey) => {
                      if (key) {
                        const location: Location = JSON.parse(String(key));
                        field.handleChange(location);
                      }

                      return;
                    }}
                    SelectionIcon={SelectionIcon}
                    offsetErrorMessage={2}
                    isInvalid={field.state.meta.errors.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                    filterByMot={mot}
                  />
                );
              }}
            />
            <Dash />
          </div>
          <div className="relative">
            <Typography level="subtext" color="tertiary" htmlFor="primaryMot">
              Main transport mode
            </Typography>
            <form.Field
              name="primaryMot"
              validators={{
                onChangeListenTo: ['origin', 'destination'],
                onChange: ({ fieldApi }) => {
                  const meta = fieldApi.getMeta();
                  fieldApi.setMeta({
                    ...meta,
                    errorMap: { ...meta.errorMap, onBlur: undefined },
                  });

                  return undefined;
                },
                onBlur: ({ value }) => {
                  if (!value) {
                    return 'Please select a mode of transportation';
                  }
                  return;
                },
              }}
              children={(field) => {
                const isShouldPulse =
                  (form.state.isPristine || !field.state.value) &&
                  !isUpdate &&
                  !isDuplicate;
                return (
                  <div
                    className={clsxMerge(
                      isShouldPulse &&
                        'w-fit rounded-lg animate-[shadowpulse_1.5s_infinite_ease-out]',
                    )}
                  >
                    <ModeOfTransportSelect
                      data-testid="primary-transport-mode-select"
                      validationBehavior="aria"
                      isRequired
                      id={field.name}
                      name={field.name}
                      onBlur={field.handleBlur}
                      selectedKey={field.getValue()}
                      onSelectionChange={(value) => {
                        field.handleChange(value);
                        const fieldsToClear = [
                          'origin',
                          'destination',
                          'placeOfLoading',
                          'placeOfDelivery',
                        ];
                        fieldsToClear.forEach((field) => {
                          form.setFieldValue(field, null);
                          form.setFieldMeta(field, {
                            isTouched: false,
                            errors: [],
                            isPristine: true,
                            errorMap: {},
                            isValidating: false,
                            touchedErrors: [],
                            isDirty: true,
                          });
                        });
                        setOriginInputValue('');
                        setDestinationInputValue('');
                        setPlaceOfLoadingInputValue('');
                        setPlaceOfDeliveryInputValue('');

                        if (value === 'sea' || value === 'air') {
                          setShowSecondaryTransport(true);
                        } else {
                          handleCloseSecondaries();
                        }

                        listeners?.primaryMot?.onChange?.(value);

                        const origin = field.form.getFieldValue('origin');
                        if (origin) {
                          // I don't know how else we can trigger a re-render of the
                          // Origin field.
                          field.form.setFieldValue('origin', { ...origin });
                        }
                      }}
                      offsetErrorMessage={true}
                      isInvalid={field.state.meta.errors.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                    />
                  </div>
                );
              }}
            />
            <Dash />
          </div>
          <div className="relative pr-9">
            <form.Field
              name="destination"
              validators={{
                onBlur: ({ value }) => {
                  if (!value) {
                    return 'Please select a destination';
                  }
                  return;
                },
              }}
              children={(field) => {
                const value = field.getValue();
                const mot = field.form.getFieldValue('primaryMot');

                const SelectionIcon = value
                  ? getSelectionIcon(value, mot)
                  : undefined;
                return (
                  <PortsAndPlacesComboBox
                    filterByMot={mot}
                    data-testid="destination-combobox"
                    label="Destination"
                    validationBehavior="aria"
                    isRequired
                    placeholder={mot ? getPlaceholderText(mot) : undefined}
                    id={field.name}
                    name={field.name}
                    onBlur={(e) =>
                      locationHandleBlur(e, field, setDestinationInputValue)
                    }
                    inputValue={destinationInputValue}
                    onInputChange={(value) => {
                      setDestinationInputValue(value);
                      return;
                    }}
                    onSelectionChange={(key: ComboBoxKey) => {
                      if (key) {
                        const location: Location = JSON.parse(String(key));
                        field.handleChange(location);

                        const mot = field.form.getFieldValue('primaryMot');
                        if (location.type === 'port' && !mot) {
                          const primaryMot =
                            location.port.type === 'marine' ? 'sea' : 'air';

                          field.form.setFieldValue('primaryMot', primaryMot);
                          listeners?.primaryMot?.onChange?.(primaryMot);

                          setShowSecondaryTransport(true);
                        }

                        if (location.type === 'place' && !mot) {
                          field.form.setFieldValue('primaryMot', 'road');
                          listeners?.primaryMot?.onChange?.('road');

                          handleCloseSecondaries();
                        }
                      }

                      return;
                    }}
                    SelectionIcon={SelectionIcon}
                    offsetErrorMessage={2}
                    isInvalid={field.state.meta.errors.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                  />
                );
              }}
            />
            {showSecondaryTransport && <Dash />}
          </div>
          {showSecondaryTransport && !showPlaceOfDelivery && (
            <Button
              data-testid="add-place-of-delivery-button"
              variant="secondary"
              width="square"
              onPress={() => setShowPlaceOfDelivery(true)}
              customStyles="w-fit"
            >
              <div className="flex items-center p-2 gap-1">
                <FaPlus />
                <span>Cover port to door - Add place of discharge</span>
              </div>
            </Button>
          )}
          <div
            data-testid="place-of-delivery-section"
            className={clsxMerge(
              'flex flex-col gap-7',
              (!showSecondaryTransport || !showPlaceOfDelivery) && 'hidden',
            )}
          >
            <div className="relative">
              <form.Field
                name="placeOfDeliveryMot"
                children={(field) => (
                  <ModeOfTransportSelect
                    data-testid="delivery-transport-mode-select"
                    id={field.name}
                    name={field.name}
                    type="secondary"
                    selectedKey={field.getValue() ?? null}
                    onSelectionChange={(value) => {
                      field.handleChange(value);

                      const placeOfDelivery =
                        field.form.getFieldValue('placeOfDelivery');
                      if (placeOfDelivery) {
                        // Not sure how else to trigger a re-render of the field
                        field.form.setFieldValue('placeOfDelivery', {
                          ...placeOfDelivery,
                        });
                      }
                    }}
                  />
                )}
              />
              <Dash />
            </div>
            <div className="flex items-center gap-x-2 w-full">
              <form.Field
                validators={{
                  onSubmit: ({ value }) => {
                    if (!value && showPlaceOfDelivery) {
                      return 'Please select a place of delivery';
                    }
                    return;
                  },
                }}
                name="placeOfDelivery"
                children={(field) => {
                  const value = field.getValue();
                  const mot = field.form.getFieldValue('placeOfDeliveryMot');

                  const SelectionIcon = value
                    ? getSelectionIcon(value, mot)
                    : undefined;
                  return (
                    <PortsAndPlacesComboBox
                      data-testid="place-of-delivery-combobox"
                      label="Place of Discharge"
                      validationBehavior="aria"
                      id={field.name}
                      name={field.name}
                      onBlur={(e) =>
                        locationHandleBlur(
                          e,
                          field,
                          setPlaceOfDeliveryInputValue,
                        )
                      }
                      filterByMot={mot ?? undefined}
                      inputValue={placeOfDeliveryInputValue ?? ''}
                      onInputChange={(value) => {
                        setPlaceOfDeliveryInputValue(value);
                        return;
                      }}
                      onSelectionChange={(key: ComboBoxKey) => {
                        if (key) {
                          const location: PlaceLocation = JSON.parse(
                            String(key),
                          );
                          field.handleChange(location);

                          field.form.setFieldValue(
                            'placeOfDeliveryMot',
                            'road',
                          );
                          const meta =
                            field.form.getFieldMeta('placeOfDeliveryMot');
                          if (!meta) return;
                          field.form.setFieldMeta('placeOfDeliveryMot', {
                            ...meta,
                            isDirty: true,
                          });
                        }

                        return;
                      }}
                      SelectionIcon={SelectionIcon}
                      offsetErrorMessage={2}
                      isInvalid={field.state.meta.errors.length > 0}
                      errorMessage={field.state.meta.errors[0] || undefined}
                    />
                  );
                }}
              />
              <Button
                variant="ghost"
                onPress={() => handleCloseSecondary('delivery')}
                data-testid="close-place-of-delivery-button"
              >
                <FaXmark className="text-system-grey-600" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
