import { FaShip } from 'react-icons/fa';
import { FaPlaneUp, FaTrain, FaTruck } from 'react-icons/fa6';

import { type ConveyanceType } from '../../../model/Shipment';
import {
  type Location,
  type PlaceLocation,
  type PortLocation,
} from '../../../network/apis/locations/types';
import { getPortSearchDisplayValue } from '../../utils/ports';

export const getSelectionIcon = (
  location?: Location | Record<string, never>,
  mot?: ConveyanceType | null,
) => {
  if (!location) return;

  switch (location.type) {
    case 'place': {
      if (!mot || mot === 'air' || mot === 'sea') return FaTruck;

      if (mot === 'road') {
        return FaTruck;
      }

      if (mot === 'rail') {
        return FaTrain;
      }

      return;
    }
    case 'port': {
      const {
        port: { type },
      } = location;

      if (type === 'marine') {
        return FaShip;
      } else if (type === 'air') {
        return FaPlaneUp;
      }

      return;
    }
  }
};

export const isLocation = (location: unknown): location is Location => {
  if (typeof location !== 'object' || location === null) return false;
  return 'port' in location || 'place' in location;
};

export const isPortLocation = (location?: Location): location is PortLocation =>
  location?.type === 'port';
export const isPlaceLocation = (
  location?: Location,
): location is PlaceLocation => location?.type === 'place';

export const getDefaultInputValue = (location?: Location | null): string => {
  if (!location) return '';

  switch (location.type) {
    case 'port': {
      return getPortSearchDisplayValue(location.port);
    }
    case 'place': {
      return location.place.description;
    }
  }
};

export const getPlaceholderText = (mot: ConveyanceType) => {
  switch (mot) {
    case 'sea': {
      return 'Search sea port';
    }
    case 'air': {
      return 'Search airport';
    }
    default: {
      return 'Search location';
    }
  }
};
