import { Currency, Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { type PremiumOffer } from '../../../../model/Quote';
import { CurrencyExchangeRate } from '../../CurrencyExchangeRate/CurrencyExchangeRate';
import { type PriceComponentTypographyProps } from '../PremiumBreakdown';
import { PriceComponent } from '../PriceComponent/PriceComponent';
import styles from './PremiumTotal.module.scss';

type PremiumTotalProps = {
  entity: PremiumOffer;
  showExchangeRate?: boolean;
  className?: string;
  rowClassname?: string;
  typographyProps?: PriceComponentTypographyProps;
};

const defaults: PriceComponentTypographyProps = {
  title: { bold: true },
  currency: { bold: true },
};

export const PremiumTotal = ({
  entity,
  showExchangeRate,
  className,
  rowClassname,
  typographyProps,
}: PremiumTotalProps) => {
  const titleProps = { ...defaults.title, ...typographyProps?.title };
  const currencyProps = { ...defaults.currency, ...typographyProps?.currency };

  const {
    premium_currency,
    premium_value,
    tax,
    exchange_rate,
    converted_customer_premium_value = 0,
    converted_tax_amount = 0,
  } = entity;

  if (!premium_value || !premium_currency) {
    return null;
  }

  const total = premium_value + (tax?.value ?? 0);
  const convertedTotal =
    converted_customer_premium_value + converted_tax_amount;

  return (
    <div>
      <div className={classnames(styles.totalRow, className)}>
        <Typography {...titleProps}>Total</Typography>
        <Currency
          value={total}
          currency={premium_currency}
          typographyProps={{ ...currencyProps, role: 'total' }}
        />
      </div>
      {showExchangeRate && exchange_rate && (
        <div className={classnames(styles.convertedTotal, rowClassname)}>
          <PriceComponent
            title={`Total in ${exchange_rate.to_currency.code}`}
            name="converted-total"
            value={convertedTotal}
            currency={exchange_rate.to_currency.code}
            typographyProps={{ title: { className: styles.title } }}
          />
          <CurrencyExchangeRate
            {...exchange_rate}
            typographyProps={{
              level: 'subtext',
              variant: 'input',
            }}
          />
        </div>
      )}
    </div>
  );
};
