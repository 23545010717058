import { FaWarehouse } from 'react-icons/fa';
import { Typography } from '@breezeai-frontend/cargo-ui';
import { capitalize } from 'lodash';

import { type StorageLocationModel } from '../types';

export function Metrics({ data }: { data: StorageLocationModel }) {
  const capacityPercentage = data.capacity
    ? Math.round(data.capacity * 100) + '%'
    : '0%';
  return (
    <div className="w-full flex flex-col space-y-8">
      <div className="w-full flex flex-row justify-start text-text-secondary px-6">
        <Typography
          level="h5"
          customStyles="border-r-2 border-solid border-system-grey-500 pr-4 mr-4 flex flex-row gap-2 justify-center items-center"
        >
          <FaWarehouse className="size-4" />
          {capitalize(data?.type)}
        </Typography>
        <Typography level="h5">{data?.address.full_address}</Typography>
      </div>
      <div className="w-full grid grid-cols-8 grid-row-1 rounded-md bg-system-grey-white p-6">
        <div className="col-span-2 flex flex-col justify-center gap-y-1">
          <Typography level="subtext" customStyles="text-text-secondary">
            IV Limit
          </Typography>
          <Typography level="h5" customStyles="text-text-secondary">
            {data?.insured_value_limit_currency?.symbol}
            {data?.insured_value_limit}
          </Typography>
        </div>
        <div className="col-span-2 flex flex-col justify-center gap-y-1">
          <Typography level="subtext" customStyles="text-text-secondary">
            Active TIV on risk
          </Typography>
          <Typography level="h5" customStyles="text-text-secondary gap-y-1">
            {data?.active_total_insured_value_currency?.symbol}
            {data?.active_total_insured_value}
          </Typography>
        </div>
        <div className="col-span-2 flex flex-col justify-center gap-y-1">
          <Typography level="subtext" customStyles="text-text-secondary">
            Capacity Used
          </Typography>
          <Typography level="h5" customStyles="text-text-secondary">
            {capacityPercentage}
          </Typography>
        </div>
      </div>
    </div>
  );
}
