import { FaTrash } from 'react-icons/fa';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, LinkButton, Typography } from '@breezeai-frontend/cargo-ui';
import { capitalize } from 'lodash';

import { useCreateStorageLocation } from '../hooks/useCreateStorageLocation';
import {
  type StorageLocationModel,
  StorageLocationStatusEnum,
  type StorageRouteParams,
} from '../types';

export default function Title({ data }: { data: StorageLocationModel }) {
  const navigate = useNavigate();
  const { policyId, storageLocationId } = useParams<StorageRouteParams>();
  const { mutate, isLoading } = useCreateStorageLocation({
    policyId,
    options: {
      onSuccess: () => {
        navigate('/storage');
      },
    },
  });

  return (
    <div className="flex flex-row sm:items-center sm:justify-between text-nowrap gap-2 w-full">
      <div className="flex flex-row justify-center items-center">
        <Link to="/storage" className="flex justify-center items-center">
          <FaChevronLeft className="size-5 mr-2" />
        </Link>
        <Typography level="h2">{`${capitalize(
          data?.location_name,
        )} ${storageLocationId}`}</Typography>
      </div>

      <div className="flex sm:flex-row flex-col items-center gap-2">
        <LinkButton
          variant="ghost"
          label="Edit"
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          href={`/storage/update/details/${storageLocationId}/${policyId}`}
          isDisabled={isLoading}
        />
        <Button
          variant="ghost"
          label="Delete"
          isLoading={isLoading}
          loadingText="Deleting..."
          leftIcon={<FaTrash />}
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          onPress={() =>
            mutate({
              status: StorageLocationStatusEnum.DELETED,
            })
          }
        />
      </div>
    </div>
  );
}
