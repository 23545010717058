import { Icon } from '@breeze-ai/ui-library';
import { Typography } from '@breezeai-frontend/cargo-ui';

import { type Quote } from '../../../../model/Quote';
import { ExclusionReasons } from '../../../components/ExclusionReasons/ExclusionReasons';
import styles from './QuoteManualReviewMessage.module.scss';

export const QuoteManualReviewMessage = ({
  exclusionReasons,
}: {
  exclusionReasons?: Quote['exclusion_reasons'];
}) => {
  return (
    <div className={styles.wrapper} data-testid="quote-manual-review-message">
      <Icon icon="list-check" variant="primary" className={styles.icon} />
      <Typography level="h2" customStyles="mb-3">
        The quote needs to be processed manually, due to some exceptions.
      </Typography>

      <Typography>We will get back to you within the next 2 hours.</Typography>

      {exclusionReasons?.length && (
        <div data-testid="exclusion-reasons-section" className="w-3/4 mt-6">
          <div className="h-[1px] w-full bg-system-grey-400 mb-6" />
          <ExclusionReasons reasons={exclusionReasons} />
        </div>
      )}
    </div>
  );
};
