import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Spinner } from '@breezeai-frontend/cargo-ui';
import { stringify } from 'query-string';

import { AuthContext } from '../../context/auth/AuthContext';
import { type User } from '../../model/User';
import { setAuthTokens } from '../../network/apis/auth/auth';
import { type AuthTokenResponse } from '../../network/apis/auth/types';
import { get } from '../../network/apis/utils';
import { bffServiceUrl } from '../../network/netconfig';

export type GetAuthTradeflowLoginCallbackResponse = AuthTokenResponse & {
  user: User;
};

const missingQueryParamMessage = (paramName: string | string[]) =>
  `Unexpected callback URL, missing parameters: ${paramName}`;

export function STSLoginCallback() {
  const [searchParams] = useSearchParams();

  const { setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const state = searchParams.get('state');
    const code = searchParams.get('code');

    if (!state) {
      throw missingQueryParamMessage('state');
    }

    if (!code) {
      throw missingQueryParamMessage('code');
    }

    const stateUrl = new URL(decodeURIComponent(state));
    const gcId = stateUrl.searchParams.get('gcid');

    if (!gcId) {
      throw missingQueryParamMessage('gcid');
    }

    const { protocol, host } = window.location;

    const params = stringify({
      gcid: gcId,
      code,
      redirect_uri: `${protocol}//${host}/login/callback`,
    });

    get<GetAuthTradeflowLoginCallbackResponse>(
      `${bffServiceUrl}/auth/tradeflow/login/callback?${params}`,
      undefined,
      { auth: false },
    ).then((data) => {
      const { id_token, refresh_token, expires_at } = data;

      setAuthTokens({ id_token, refresh_token, expires_at });
      setAuthState({ authenticated: true });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner className="self-center" />;
}
