import { type ReactNode } from 'react';

/**
 * A shared file to reside atomic validation functions that can be
 * used throughout the platform.
 */
export type Validator = (input: string) => { valid: boolean; reason?: string };
export type ValidationOptions = {
  required?: boolean;
};

export type ValidationResult = {
  field: string;
  error: boolean;
  reason?: ReactNode;
};

export const VALIDATION_EXPRESSIONS = {
  commaSeparatedList: /^([\w ]+)$|^(([\w ]+,)+[\w ]+|)$/,
  emailAddress:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
};

export const isNonEmpty: Validator = (
  input?: string | number | boolean | null,
) => {
  const valid =
    input !== undefined && input !== null && `${input}`.trim().length > 0;

  return {
    valid,
    reason: !valid ? 'Value must not be empty' : undefined,
  };
};

export const isValidEmail: Validator = (input: string) => {
  const valid = VALIDATION_EXPRESSIONS.emailAddress.test(input);

  return {
    valid,
    reason: !valid ? 'Invalid email address format' : undefined,
  };
};

export const isPositive: Validator = (input: string) => {
  const value = Number(input);
  const valid = value >= 0;

  return {
    valid,
    reason: !valid ? 'Value cannot be negative' : undefined,
  };
};

export const isInValidCommodityRange: Validator = (input: string) => {
  const value = Number(input);
  const valid = value >= 1;

  return {
    valid,
    reason: !valid ? 'Value is out of the valid range' : undefined,
  };
};

export const isValidCommaSeparatedList: Validator = (input: string) => {
  const valid = VALIDATION_EXPRESSIONS.commaSeparatedList.test(input);

  return {
    valid,
    reason: !valid
      ? 'Please make sure you entered a valid comma-separated list'
      : undefined,
  };
};
