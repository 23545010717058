/* eslint-disable react/no-children-prop */

import { FaWarehouse } from 'react-icons/fa';
import { FaHouseFloodWater } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { Toggle } from '@breeze-ai/ui-library';
import {
  Button,
  clsxMerge,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
  TextField,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { capitalize } from 'lodash';
import { z } from 'zod';

import { useAppConfiguration } from '../../../../context/app-configuration/AppConfigurationProvider';
import { useUserSetting } from '../../../../context/auth/auth-hooks';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { queryClient } from '../../../../providers/ReactQueryProvider';
import { PlacesComboBox } from '../../../components/PlacesComboBox/PlacesComboBox';
import { useCreateStorageLocation } from '../../hooks/useCreateStorageLocation';
import { useStorageLocation } from '../../hooks/useStorageLocation';
import {
  type CreateStorageLocationPayload,
  type StorageCreationModalRouteParams,
  StorageLocationTypeEnum,
} from '../../types';

const storageDetailsFormSchema = z.object({
  name: z.string().min(1),
  address: z.object({
    place_id: z.string().min(1),
    description: z.string().min(1),
    session_token: z.optional(z.string().min(1)),
  }),
  type: z.nativeEnum(StorageLocationTypeEnum),
  isOwnedByOthers: z.boolean(),
  currency: z.string().min(1),
  ivLimit: z.string().min(1),
  activeTiv: z.string().min(1),
  notes: z.string().min(1),
});

type StorageDetailsFormProps = z.infer<typeof storageDetailsFormSchema>;

export function StorageInputsForm({ isUpdate }: { isUpdate: boolean }) {
  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');
  const { currencies } = useAppConfiguration();
  const { policyId } = useParams<StorageCreationModalRouteParams>();

  const { data } = useStorageLocation({
    policyId,
    options: {
      suspense: true,
    },
  });

  const { mutate, isLoading: isMutating } = useCreateStorageLocation({
    policyId,
  });
  const navigate = useNavigate();

  const form = useForm<StorageDetailsFormProps, typeof zodValidator>({
    validatorAdapter: zodValidator,
    defaultValues: {
      name: data?.location_name ?? '',
      address: {
        place_id: data?.address.provider_place_uuid ?? '',
        description: data?.address.full_address ?? '',
      },
      type: data?.type ?? StorageLocationTypeEnum.WAREHOUSE,
      isOwnedByOthers: data?.owned_or_operated_by_others ?? false,
      currency:
        data?.active_total_insured_value_currency?.code ?? defaultCurrency,
      ivLimit: String(data?.insured_value_limit ?? ''),
      activeTiv: String(data?.active_total_insured_value ?? ''),
      notes: data?.notes ?? '',
    },
    onSubmit: async ({ formApi, value }) => {
      const createPayload: CreateStorageLocationPayload = {
        location_name: value.name,
        location_type: value.type,
        location_address_provider_details: {
          place_id: value.address.place_id,
          session_token: value.address.session_token,
        },
        owned_or_operated_by_others: value.isOwnedByOthers,
        // TODO - If zero, should be undefined?
        insured_value_limit: Number(value.ivLimit),
        insured_value_limit_currency: value.currency,
        active_total_insured_value: Number(value.activeTiv),
        active_total_insured_value_currency: value.currency,
        notes: value.notes,
      };
      const updatePayload = {
        location_name: formApi.state.fieldMeta['name'].isDirty
          ? value.name
          : undefined,
        location_type: formApi.state.fieldMeta['type'].isDirty
          ? value.type
          : undefined,
        location_address_provider_details: formApi.state.fieldMeta['address']
          .isDirty
          ? {
              place_id: value.address.place_id,
              session_token: value.address.session_token,
            }
          : undefined,
        owned_or_operated_by_others: formApi.state.fieldMeta['isOwnedByOthers']
          .isDirty
          ? value.isOwnedByOthers
          : undefined,
        // TODO - If zero, should be undefined?
        insured_value_limit: formApi.state.fieldMeta['ivLimit'].isDirty
          ? Number(value.ivLimit)
          : undefined,
        insured_value_limit_currency: formApi.state.fieldMeta['currency']
          .isDirty
          ? value.currency
          : undefined,
        active_total_insured_value: formApi.state.fieldMeta['activeTiv'].isDirty
          ? Number(value.activeTiv)
          : undefined,
        active_total_insured_value_currency: formApi.state.fieldMeta['currency']
          .isDirty
          ? value.currency
          : undefined,
        notes: value.notes !== data?.notes ? value.notes : undefined,
      };

      const payload = isUpdate ? updatePayload : createPayload;

      mutate(payload, {
        onError: (error) => {
          // These validation errors are handled by the form but we can still handle if error response is received
          const { error_type, message } = error.response.data;
          switch (error_type) {
            case 'StorageLocationAddressAlreadyExists': {
              form.setFieldMeta('address', {
                errorMap: {
                  onSubmit: 'This address already exists as a storage location',
                },
                errors: ['This address already exists as a storage location'],
                isDirty: true,
                isTouched: true,
                isPristine: false,
                isValidating: false,
                touchedErrors: [
                  'This address already exists as a storage location',
                ],
              });
              break;
            }
            case 'StorageLocationInsuredValueException': {
              form.setFieldMeta('activeTiv', {
                errorMap: {
                  onSubmit: message,
                },
                errors: [message],
                isDirty: true,
                isTouched: true,
                isPristine: false,
                isValidating: false,
                touchedErrors: [message],
              });
              break;
            }
          }
        },
        onSuccess: ({ data }) => {
          if (isUpdate) {
            queryClient.setQueryData(
              ['storageLocation', String(data.id)],
              data,
            );
            navigate(`/storage/details/${data.location_id}/${data.id}`, {
              replace: true,
            });
          } else {
            navigate(`/storage/create/files/${data.location_id}/${data.id}`, {
              replace: true,
            });
          }
        },
      });
    },
  });

  return (
    <div className="h-full">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        className="space-y-6"
      >
        {/* Details */}
        <div className="flex flex-col gap-y-3">
          <Typography level="h5">Details</Typography>
          <div className="flex flex-col gap-y-5">
            <div className="flex flex-row gap-x-6 ">
              <form.Field
                name="name"
                validators={{
                  onSubmit: z.string().min(1, 'Please enter a location name'),
                }}
                children={(field) => (
                  <TextField
                    isRequired
                    label="Location Name"
                    placeholder="Enter location name"
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={field.handleChange}
                    isInvalid={field.state.meta.errors?.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                  />
                )}
              />
              <form.Field
                name="address"
                validators={{
                  onBlur: ({ value }) => {
                    if (!value?.place_id) {
                      return 'Please select a location';
                    }
                    return;
                  },
                }}
                children={(field) => {
                  return (
                    <PlacesComboBox
                      isRequired
                      placeholder="Enter location address"
                      label="Location Address"
                      id={field.name}
                      name={field.name}
                      selectedKey={JSON.stringify(field.state.value)}
                      onInputChange={(value) => {
                        field.handleChange({
                          ...field.state.value,
                          place_id: '',
                          description: value,
                        });
                      }}
                      inputValue={field.state.value?.description}
                      onBlur={() => {
                        if (field.state.value?.place_id.length === 0) {
                          field.handleChange({
                            ...field.state.value,
                            place_id: '',
                            description: '',
                          });
                        }
                        field.handleBlur();
                      }}
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                      onSelectionChange={(option) => {
                        const parsedOption = JSON.parse(String(option));
                        const isFieldUpdated =
                          parsedOption.place_id !==
                          field.prevState.value?.place_id;
                        isFieldUpdated && field.handleChange(parsedOption);
                      }}
                    />
                  );
                }}
              />
            </div>
            <form.Field
              name="type"
              children={(field) => (
                <RadioGroup
                  isRequired
                  label="Location Type"
                  id={field.name}
                  name={field.name}
                  defaultValue={field.state.value}
                  onChange={(value) =>
                    field.handleChange(value as StorageLocationTypeEnum)
                  }
                  orientation="horizontal"
                >
                  {[
                    {
                      name: StorageLocationTypeEnum.WAREHOUSE,
                      icon: <FaWarehouse className="size-4" />,
                    },
                    {
                      name: StorageLocationTypeEnum.FLOATING,
                      icon: <FaHouseFloodWater className="size-4" />,
                    },
                  ].map(({ name, icon }) => (
                    <div
                      key={name}
                      className="bg-white px-4 py-3 border-1 border-field-border-default rounded-xl w-52 flex justify-start items-center gap-2"
                    >
                      <Radio value={name}>
                        <Typography>{capitalize(name)}</Typography>
                      </Radio>
                      <div
                        className={clsxMerge(
                          field.state.value === name &&
                            'text-buttons-primary-bg-default',
                        )}
                      >
                        {icon}
                      </div>
                    </div>
                  ))}
                </RadioGroup>
              )}
            />
            <form.Field
              name="isOwnedByOthers"
              children={(field) => (
                <Toggle
                  label="Warehouse owned/operated by others"
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onChange={field.handleChange}
                />
              )}
            />
          </div>
        </div>

        {/* Capacity */}
        <div className="flex flex-col gap-y-3">
          <span className="flex flex-row gap-0.5">
            <Typography level="h5">Capacity</Typography>
            <Typography level="base" color="tertiary">
              {`(optional)`}
            </Typography>
          </span>
          <div className="flex flex-row gap-x-6">
            <form.Field
              name="currency"
              children={(field) => (
                <Select
                  className="basis-1/6"
                  label="Currency"
                  placeholder="Select currency"
                  id={field.name}
                  name={field.name}
                  defaultSelectedKey={field.state.value || defaultCurrency}
                  onBlur={field.handleBlur}
                  onSelectionChange={(value) =>
                    field.handleChange(value.toString())
                  }
                  isInvalid={field.state.meta.errors?.length > 0}
                  errorMessage={field.state.meta.errors.join(', ')}
                >
                  {currencies.map(({ code, symbol }) => (
                    <SelectItem
                      key={code}
                      id={code}
                      className="flex justify-center"
                    >
                      {symbol}
                    </SelectItem>
                  ))}
                </Select>
              )}
            />
            <form.Field
              name="ivLimit"
              children={(field) => (
                <TextField
                  label="IV Limit"
                  placeholder="0.00"
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={field.handleChange}
                  isInvalid={field.state.meta.errors?.length > 0}
                  errorMessage={field.state.meta.errors.join(', ')}
                />
              )}
            />
            <form.Field
              name="activeTiv"
              validators={{
                onBlur: ({ value, fieldApi }) => {
                  if (
                    Number(value) > Number(fieldApi.form.state.values.ivLimit)
                  ) {
                    return 'Active TIV on risk cannot be greater than the IV Limit';
                  }
                  return;
                },
              }}
              children={(field) => (
                <TextField
                  label="Active TIV on risk"
                  placeholder="0.00"
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={field.handleChange}
                  isInvalid={field.state.meta.errors?.length > 0}
                  errorMessage={field.state.meta.errors.join(', ')}
                />
              )}
            />
          </div>
        </div>

        {/* Notes */}
        <div className="flex flex-col gap-y-3">
          <Typography level="h5">Notes</Typography>
          <form.Field
            name="notes"
            children={(field) => (
              <TextField
                inputStyles="min-h-36 resize-none "
                variant="area"
                aria-label="Notes"
                placeholder="Enter notes"
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={field.handleChange}
              />
            )}
          />
        </div>

        {/* Submission Buttons */}
        <form.Subscribe
          selector={(state) => [state.isDirty]}
          children={([isDirty]) => (
            <div className="flex gap-4 w-full justify-end">
              <Button
                variant="ghost"
                size="large"
                type="reset"
                width="auto"
                label={isUpdate ? 'Cancel Changes' : 'Cancel'}
                isDisabled={!isDirty || isMutating}
              />
              {isUpdate ? (
                <Button
                  size="large"
                  width="auto"
                  type="submit"
                  loadingText="Updating Storage Location..."
                  label="Save Changes"
                  isLoading={isMutating}
                  isDisabled={!isDirty}
                />
              ) : (
                <Button
                  size="large"
                  width="auto"
                  type="submit"
                  loadingText="Next"
                  label="Next"
                  isLoading={isMutating}
                />
              )}
            </div>
          )}
        />
      </form>
    </div>
  );
}
