const THEME = import.meta.env.VITE_APP_THEME;
const isRecognisedTheme = (theme: string): theme is 'breeze' | 'wtw' => {
  return theme === 'breeze' || theme === 'wtw';
};

export const getTheme = () => {
  if (!isRecognisedTheme(THEME)) {
    console.error('Unrecognised theme');
    return 'breeze';
  }
  return THEME;
};
