import classnames from 'classnames';

import { usePolicy } from '../../../../network/apis/policies/hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { PageSection } from '../../../components/PageSection/PageSection';
import { PremiumBreakdown } from '../../../components/PremiumBreakdown/PremiumBreakdown';
import { type PolicyRouteParams } from '../PolicyDetailsPage';
import styles from './PolicyPriceBreakdown.module.scss';

type PolicyPriceBreakdownProps = {
  className?: string;
};

export const PolicyPriceBreakdown = ({
  className,
}: PolicyPriceBreakdownProps) => {
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const { data: policy } = usePolicy({ policyId });

  if (!policy?.quote) {
    return null;
  }

  return (
    <PageSection
      title="Price details"
      className={classnames(styles.wrapper, className)}
    >
      <PremiumBreakdown
        entity={policy?.quote}
        className={styles.breakdown}
        rowClassname={styles.priceComponentRow}
        totalRowClassname={styles.totalRow}
        componentProps={{
          components: { currency: { level: 'base' } },
          total: { title: { level: 'h5' }, currency: { level: 'h2' } },
        }}
      />
    </PageSection>
  );
};
