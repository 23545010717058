import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../context/auth/auth-hooks';
import { useTracking } from '../../utils/snowplow/hooks';
import { ClaimsTable } from './ClaimsTable/ClaimsTable';

export const ClaimsPage = () => {
  useTracking();
  const user = useUser();

  if (!user) {
    return null;
  }

  return (
    <SectionLayout
      title="Claims"
      data-testid="claims-page"
      actions={
        <LinkButton
          // TODO THEMING: Replace href with the enum
          href="/claims/start"
          data-testid="get-quote-button"
          variant="primary"
          width="auto"
          label="File A Claim"
        />
      }
    >
      <ClaimsTable />
    </SectionLayout>
  );
};
