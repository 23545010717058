import { FaCircleInfo } from 'react-icons/fa6';
import {
  Button,
  clsxMerge,
  Tooltip,
  TooltipTriggerWrapper,
  Typography,
} from '@breezeai-frontend/cargo-ui';

import { formatPrice } from '../../../../../../packages/cargo-ui/src/utils/tools';
import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type SupportedCurrencies } from '../../../model/CurrencyValue';
import { getAppLocale } from '../../../utils/locale';
import { type QuotePriceProperties } from '../../quotes/types';

interface QuotePriceBreakdownTooltipProps extends QuotePriceProperties {
  premium_value: number;
  premium_currency: SupportedCurrencies;
  className?: string;
  defaultOpen?: React.ComponentProps<
    typeof TooltipTriggerWrapper
  >['defaultOpen'];
}

export function QuotePriceBreakdownTooltip({
  premium_value,
  premium_currency,
  tax,
  exchange_rate,
  converted_customer_premium_value = 0,
  converted_tax_amount = 0,
  className,
  defaultOpen = false,
}: QuotePriceBreakdownTooltipProps) {
  const locale = getAppLocale();
  const showExchangeRate = useFeatureToggle('show_currencies_exchange_rates');

  const premiumPrice = formatPrice({
    currency: premium_currency,
    value: premium_value,
    locale,
  });

  const taxPrice =
    tax && !!tax.value
      ? formatPrice({ currency: tax.currency_code, value: tax.value, locale })
      : 0;

  const total = premium_value + (tax?.value ?? 0);
  const totalPrice = formatPrice({
    currency: premium_currency,
    value: total,
    locale,
  });

  const convertedTotal =
    converted_customer_premium_value + converted_tax_amount;
  const convertedTotalPrice = exchange_rate
    ? formatPrice({
        currency: exchange_rate.to_currency.code,
        value: convertedTotal,
        locale,
      })
    : 0;

  const commonTextClassNames = 'text-sm leading-4';
  const iconDefaultClassName = 'w-[18px] h-[18px] text-system-grey-750';

  return (
    <TooltipTriggerWrapper delay={0} defaultOpen={defaultOpen}>
      <Button
        data-testid="quote-price-breakdown-tooltip-trigger"
        variant="ghost"
        customStyles="w-min h-min min-w-0 rounded-full hover:bg-[none]"
      >
        <FaCircleInfo className={clsxMerge(iconDefaultClassName, className)} />
      </Button>
      <Tooltip
        data-testid="quote-price-breakdown-tooltip"
        placement="top"
        offset={16}
        showArrow={false}
        className="flex gap-2 flex-col min-w-[205px] py-3 px-0"
      >
        <div className="flex items-center gap-2 mx-3">
          <FaCircleInfo className={iconDefaultClassName} />
          <Typography customStyles="font-bold">Price Details</Typography>
        </div>
        <div className="flex gap-2 justify-between mx-3">
          <Typography
            customStyles={clsxMerge(
              commonTextClassNames,
              'text-system-grey-700',
            )}
          >
            Premium
          </Typography>
          <Typography customStyles={commonTextClassNames}>
            {premiumPrice}
          </Typography>
        </div>
        {tax && !!tax.value && (
          <div className="flex gap-2 justify-between mx-3">
            <Typography
              customStyles={clsxMerge(
                commonTextClassNames,
                'text-system-grey-700',
              )}
            >
              Taxes
            </Typography>
            <Typography customStyles={commonTextClassNames}>
              {taxPrice}
            </Typography>
          </div>
        )}
        <div className="flex gap-2 justify-between mx-1 bg-system-grey-70 p-2 rounded-lg">
          <Typography
            customStyles={clsxMerge(commonTextClassNames, 'font-bold')}
          >
            Total
          </Typography>
          <Typography
            customStyles={clsxMerge(commonTextClassNames, 'font-bold')}
          >
            {totalPrice}
          </Typography>
        </div>
        {showExchangeRate && exchange_rate && (
          <>
            <div className="flex gap-2 justify-between mx-3">
              <Typography customStyles={commonTextClassNames}>
                Total in {exchange_rate.to_currency.code}
              </Typography>
              <Typography customStyles={commonTextClassNames}>
                {convertedTotalPrice}
              </Typography>
            </div>
            <Typography
              customStyles={clsxMerge(
                commonTextClassNames,
                'text-system-grey-700 mx-3',
              )}
            >
              Exchange rate: {exchange_rate.exchange_rate.toFixed(4)}{' '}
              {exchange_rate.to_currency.code}
            </Typography>
          </>
        )}
      </Tooltip>
    </TooltipTriggerWrapper>
  );
}
