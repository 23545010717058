import { useMemo } from 'react';
import {
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  Link,
  type TableColumn,
  TextCell,
} from '@breeze-ai/ui-library';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type CurrencyValue } from '../../../model/CurrencyValue';
import { type InvoicePolicyItem } from '../../../network/apis/invoices/types';
import { useAppNavigation } from '../../../router/use-app-navigation';

export const useBillingItemsTableColumns = () => {
  const { navigateToPolicy } = useAppNavigation();
  const sortingEnabled = useFeatureToggle('enable_column_sorting');

  return useMemo(
    (): TableColumn<InvoicePolicyItem>[] => [
      {
        ...getBaseColumnDefinition('cargo_owner', 'Cargo Owner'),
        sortable: false,
        flex: 2,
        valueGetter: ({ row }) => row.cargo_owner?.company_name,
        renderCell: ({ row }) => (
          <TextCell>
            {row.cargo_owner?.company_name ?? row.cargo_owner?.name}
          </TextCell>
        ),
      },
      {
        ...getBaseColumnDefinition('issue_date', 'Policy Issue Date'),
        type: 'date',
        sortable: sortingEnabled,
        valueGetter: ({ row }) => row.policy_issue_date,
        renderCell: ({ row }) => <DateCell value={row.policy_issue_date} />,
        minWidth: 180,
      },
      {
        ...getBaseColumnDefinition('certificate_id', 'Policy #'),
        sortable: sortingEnabled,
        valueGetter: ({ row }) => row.certificate_number,
        renderCell: ({ row }) => (
          <TextCell>
            <Link
              role="policy-link"
              icon="up-right-from-square"
              onClick={() => navigateToPolicy(row.id, { newTab: true })}
              newTab={true}
              underline={false}
              // TODO THEMING - remove inline style
              style={{ color: 'var(--buttons-primary-bg-default)' }}
            >
              {row.certificate_number}
            </Link>
          </TextCell>
        ),
      },
      {
        ...getBaseColumnDefinition('external_reference', 'Reference #'),
        sortable: sortingEnabled,
        valueGetter: ({ row }) => row.external_reference,
        renderCell: ({ row }) => <TextCell>{row.external_reference}</TextCell>,
        minWidth: 160,
      },
      {
        ...getBaseColumnDefinition('customer_premium_value', 'Premium'),
        sortable: sortingEnabled,
        type: 'number',
        valueGetter: ({ value }) => (value as CurrencyValue)?.value ?? 0,
        renderCell: ({ row }) => <CurrencyCell currencyValue={row.premium} />,
      },
      {
        ...getBaseColumnDefinition('customer_tax_amount', 'Tax'),
        sortable: sortingEnabled,
        type: 'number',
        valueGetter: ({ value }) => (value as CurrencyValue)?.value ?? 0,
        renderCell: ({ row }) => <CurrencyCell currencyValue={row.tax} />,
      },
      {
        ...getBaseColumnDefinition('markup_fee_value', 'Markup'),
        sortable: sortingEnabled,
        type: 'number',
        valueGetter: ({ value }) => (value as CurrencyValue)?.value ?? 0,
        renderCell: ({ row }) => <CurrencyCell currencyValue={row.markup} />,
      },
    ],
    [navigateToPolicy, sortingEnabled],
  );
};
