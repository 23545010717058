import React, { useMemo, useState } from 'react';
import { FaWarehouse } from 'react-icons/fa';
import { FaEllipsisVertical, FaPen } from 'react-icons/fa6';
import { FaHouseFloodWater } from 'react-icons/fa6';
import { FaTrash } from 'react-icons/fa6';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { PiClockCountdown } from 'react-icons/pi';
import {
  CurrencyCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
  Tooltip,
} from '@breeze-ai/ui-library';
import {
  Button,
  LinkButton,
  ListBox,
  ListBoxItem,
  Popover,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { capitalize } from 'lodash';
import moment from 'moment';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { useCreateStorageLocation } from '../../hooks/useCreateStorageLocation';
import {
  type StorageLocationModel,
  StorageLocationStatusEnum,
  StorageLocationTypeEnum,
} from '../../types';

const ActionMenu = ({
  row,
  refreshTable,
}: {
  row: StorageLocationModel;
  refreshTable: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { mutate, isLoading } = useCreateStorageLocation({
    policyId: row.id.toString(),
    options: {
      onSuccess: () => {
        refreshTable();
      },
      onSettled: () => {
        setIsOpen(false);
      },
    },
  });

  return (
    <Popover
      isOpen={isOpen}
      offset={-7}
      placement="left top"
      isKeyboardDismissDisabled
      onOpenChange={(open) => setIsOpen(open)}
      anchorElement={
        <Button
          leftIcon={<FaEllipsisVertical />}
          variant="ghost"
          onPress={() => setIsOpen(!isOpen)}
        />
      }
    >
      <ListBox className="space-y-1 flex flex-col" aria-label="Actions">
        <ListBoxItem className="p-0" textValue="Edit Details">
          <LinkButton
            onPress={() => {
              setIsOpen(false);
            }}
            customStyles="justify-start rounded-lg text-text-primary"
            width="full"
            href={`storage/update/inputs/${row.location_id}/${row.id}`}
            leftIcon={<FaPen className="fill-icons-primary" />}
            variant="ghost"
            isDisabled={isLoading}
          >
            Edit Details
          </LinkButton>
        </ListBoxItem>
        <ListBoxItem className="p-0" textValue="Edit Details">
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            customStyles="justify-start rounded-lg text-text-primary"
            width="full"
            leftIcon={<FaTrash className="fill-icons-primary" />}
            variant="ghost"
            onPress={() => {
              mutate({
                status: StorageLocationStatusEnum.DELETED,
              });
            }}
          >
            {isLoading ? 'Deleting...' : 'Delete Location'}
          </Button>
        </ListBoxItem>
      </ListBox>
    </Popover>
  );
};

export const useStorageLocationsTableColumns = ({
  refreshTable,
}: {
  refreshTable: () => void;
}): TableColumn<StorageLocationModel>[] => {
  const sortingEnabled = useFeatureToggle('enable_column_sorting');

  return useMemo(
    () => [
      {
        ...getBaseColumnDefinition('status', ''),
        valueGetter: ({ row }) => {
          const expiryDate = row.active_storage_survey_report_file?.expiry_date;
          const expired = expiryDate && moment(expiryDate).isBefore(moment());

          // Check if the expiry date is within 15 days
          const expiringSoon =
            expiryDate && moment(expiryDate).isBefore(moment().add(15, 'days'));

          if (expired) {
            return (
              <div className="rounded-full p-2 bg-system-red-100">
                <FaTriangleExclamation
                  aria-label="Expired"
                  className="bg-system-red-100 fill-system-red-500 size-3"
                />
              </div>
            );
          }
          if (expiringSoon && !expired) {
            return (
              <div className="rounded-full p-2 bg-system-orange-100">
                <PiClockCountdown
                  aria-label="Expiring Soon"
                  className="fill-system-orange-500  bg-system-orange-100 size-3"
                />
              </div>
            );
          }
          return null;
        },
        renderCell: ({ value }) => value as React.ReactNode,
        maxWidth: 45,
        sortable: false,
      },
      {
        ...getBaseColumnDefinition('location_name', 'Location Name'),
        valueGetter: ({ row }) => row.location_name,
        renderCell: ({ value }) => (
          <div className="flex flex-row items-center gap-2 truncate w-full">
            <TextCell>{value as string}</TextCell>
          </div>
        ),
        sortable: false,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('location_id', 'Location ID'),
        valueGetter: ({ row }) => row.location_id,
        renderCell: ({ value }) => <TextCell>{String(value)}</TextCell>,
        sortable: sortingEnabled,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('address', 'Address'),
        valueGetter: ({ row }) => row.address.full_address,
        renderCell: ({ value }) => (
          <Tooltip content={String(value)}>
            <span className="truncate">{String(value)}</span>
          </Tooltip>
        ),
        sortable: false,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('type', 'Location Type'),
        valueGetter: ({ row }) => row.type,
        renderCell: ({ value }) => (
          <span className="flex flex-row items-center gap-2">
            {value === StorageLocationTypeEnum.WAREHOUSE ? (
              <FaWarehouse className="size-4 fill-icons-default" />
            ) : (
              <FaHouseFloodWater className="size-4 fill-icons-default" />
            )}
            {capitalize(String(value))}
          </span>
        ),
        sortable: sortingEnabled,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition('insured_value_limit', 'IV Limit'),
        type: 'number',
        valueGetter: ({ row }) => row?.insured_value_limit,
        renderCell: ({ row }) => (
          <CurrencyCell
            value={row?.insured_value_limit}
            currency={row?.insured_value_limit_currency?.code}
          />
        ),
        sortable: sortingEnabled,
        minWidth: 150,
      },
      {
        ...getBaseColumnDefinition(
          'active_total_insured_value',
          'Active TIV On Risk',
        ),
        type: 'number',
        valueGetter: ({ row }) => row?.active_total_insured_value,
        renderCell: ({ row }) => (
          <CurrencyCell
            value={row?.active_total_insured_value}
            currency={row?.active_total_insured_value_currency?.code}
          />
        ),
        sortable: sortingEnabled,
        minWidth: 200,
      },
      {
        ...getBaseColumnDefinition('capacity', 'Capacity'),
        sortable: sortingEnabled,
        valueGetter: ({ row }) =>
          row.capacity ? Math.round(row.capacity * 100) + '%' : '0%',
        renderCell: ({ value, row }) => {
          return (
            <div className="flex flex-row gap-1 min-w-32 justify-between items-center">
              <span className="flex flex-row gap-1 items-center w-full h-5">
                <Typography level="subtext" color="primary">
                  {String(value)}
                </Typography>
              </span>
              <ActionMenu row={row} refreshTable={refreshTable} />
            </div>
          );
        },
      },
    ],
    [sortingEnabled, refreshTable],
  );
};
