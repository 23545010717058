import { Currency, Icon, Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type Quote } from '../../../model/Quote';
import { FloatingBreakdownPopover } from '../PremiumBreakdown/FloatingPremiumBreakdown/FloatingBreakdownPopover';
import styles from './QuotePremium.module.scss';

export type QuotePremiumProps = {
  quote: Quote;
};

// TODO(https://breeze.fibery.io/Breeze/Task/Additional-Clauses---Post-release-cleanup-2353)
// remove this component after Additional Clauses release.
export const QuotePremium = ({ quote }: QuotePremiumProps) => {
  const showExchangeRate = useFeatureToggle('show_currencies_exchange_rates');
  const {
    premium_value,
    premium_currency,
    exchange_rate,
    converted_customer_premium_value = 0,
    converted_tax_amount = 0,
    tax,
  } = quote;

  if (!premium_value || !premium_currency) {
    return null;
  }

  const total = (tax?.value ?? 0) + premium_value;
  const convertedTotal =
    converted_customer_premium_value + converted_tax_amount;

  const breakdown = (
    <FloatingBreakdownPopover
      entity={quote}
      placement="right-bottom"
      anchorElement={
        <Icon icon="info-circle" size="sm" role="premium-breakdown-anchor" />
      }
    />
  );

  return (
    <div className={classnames(styles.priceBubble, 'bg-cards-total-amount-bg')}>
      <div className={styles.description}>
        <Typography>Total</Typography>
        {breakdown}
      </div>
      <Currency
        value={total}
        currency={premium_currency}
        typographyProps={{ level: 'h1' }}
        testId="premium-rate"
      />
      {showExchangeRate && exchange_rate && (
        <Currency
          value={convertedTotal}
          currency={exchange_rate.to_currency.code}
          typographyProps={{ level: 'h3' }}
          testId="converted-premium-rate"
        />
      )}
    </div>
  );
};
