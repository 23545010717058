import React from 'react';
import { clsxMerge, Typography } from '@breezeai-frontend/cargo-ui';

import { formatPrice } from '../../../../../../packages/cargo-ui/src/utils/tools';
import { type AdditionalClause } from '../../../model/AdditionalClause';
import { getAppLocale } from '../../../utils/locale';

const getDeductibleText = ({
  deductible_minimum_amount,
  deductible_minimum_amount_currency,
  deductible_percentage,
}: DeductibleProperties) => {
  const locale = getAppLocale();

  const currencyCode = deductible_minimum_amount_currency?.code || '';

  const amount = deductible_minimum_amount
    ? formatPrice({
        currency: currencyCode,
        value: deductible_minimum_amount,
        locale,
      })
    : '';

  const roundedPercentage = deductible_percentage
    ? parseFloat(deductible_percentage.toFixed(2).toString())
    : '';

  if (deductible_minimum_amount && deductible_percentage) {
    return `Subject to a deductible of ${roundedPercentage}% (minimum ${amount}) per claim`;
  }

  if (deductible_minimum_amount) {
    return `Subject to a deductible of ${amount} per claim`;
  }

  if (deductible_percentage) {
    return `Subject to a deductible of ${roundedPercentage}% per claim`;
  }

  return '';
};

type DeductibleProperties = Pick<
  AdditionalClause,
  | 'deductible_minimum_amount'
  | 'deductible_minimum_amount_currency'
  | 'deductible_percentage'
>;

interface DeductibleMessageProps extends DeductibleProperties {
  className?: React.ComponentProps<typeof Typography>['customStyles'];
}

export function DeductibleMessage({
  className,
  ...props
}: DeductibleMessageProps) {
  const text = getDeductibleText(props);

  if (!text) return null;

  return (
    <Typography customStyles={clsxMerge('text-left', className)}>
      {text}
    </Typography>
  );
}
