import { type ComponentProps } from 'react';
import { Badge, Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { type InvoiceStatus } from '../../../model/Invoice';
import styles from './InvoiceStatusBadge.module.scss';

type InvoiceStatusBadgeProps = {
  status: InvoiceStatus;
  className?: string;
};

const variantByStatus: Record<
  InvoiceStatus,
  ComponentProps<typeof Badge>['variant']
> = {
  paid: 'success',
  overdue: 'error',
  pending: 'default',
};

export const InvoiceStatusBadge = ({
  status,
  className,
}: InvoiceStatusBadgeProps) => {
  const classes = classnames(styles.invoiceStatusBadge, className);
  const variant = variantByStatus[status];

  return (
    <Badge variant={variant} className={classes}>
      <Typography level="subtext" variant={variant} capitalize>
        {status}
      </Typography>
    </Badge>
  );
};
