import { FaCheckCircle, FaPlus } from 'react-icons/fa';

import { LinkButton } from '../../../../components/Button/LinkButton';
import { Typography } from '../../../../components/Typography/Typography';
import { Modal, type ModalProps } from '../../../../layouts/Modal/Modal';
export function CreateCertificateModal({
  onExit,
  viewCertificateLink,
  ...props
}: {
  onExit: () => void;
  viewCertificateLink?: string;
} & Omit<ModalProps, 'children'>) {
  return (
    <Modal
      {...props}
      data-testid="create-certificate-modal"
      onExit={onExit}
      isKeyboardDismissDisabled
      showExitButton
      className="max-w-xl"
      heading={
        <div className="flex items-center flex-col py-3 space-y-3">
          <>
            <FaCheckCircle size={42} className="fill-system-green-500" />
            <Typography level="h2" customStyles="text-center">
              Your certificate is ready!
            </Typography>
            <Typography color="secondary" customStyles="text-center w-[308px]">
              You can now view your certificate in the certificates page!
            </Typography>
          </>
        </div>
      }
    >
      <div className="flex flex-row gap-3 py-6 w-full">
        <LinkButton
          variant="ghost"
          leftIcon={<FaPlus />}
          size="medium"
          width="full"
          href="/certificates/create"
          onPress={() => {
            onExit && onExit();
          }}
          label="Create new Certificate"
        />
        <LinkButton
          data-testid="view-certificate-button"
          width="full"
          href={viewCertificateLink}
          label="View Certificate"
          size="medium"
        />
      </div>
    </Modal>
  );
}
