import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { PlatformError } from '../../../router/PlatformError/PlatformError';
import { useTracking } from '../../../utils/snowplow/hooks';
import { CertificatesTable } from './CertificatesTable/CertificatesTable';

export const CertificatesPage = () => {
  useTracking();
  const isEnabled = useFeatureToggle('enable_certificates_page');

  return isEnabled ? (
    <SectionLayout
      title="Certificates"
      data-testid="certificates-page"
      actions={
        <LinkButton
          size="small"
          data-testid="get-certificate-button"
          href="/certificates/create"
          width="auto"
          label="Get a Certificate"
        />
      }
    >
      <CertificatesTable />
    </SectionLayout>
  ) : (
    <PlatformError />
  );
};
