import { type ReactNode, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorMessage } from '@breeze-ai/ui-library';

import { type ErrorResponse } from '../../../network/apis/types';
import { PageNotFound } from '../../../router/PageNotFound';
import { SectionContext, useSectionError } from './SectionContext';

type SectionProps = {
  enabled: boolean;
  errorComponent?: ReactNode;
};

const SectionError = () => {
  const { status } = useSectionError();

  return <ErrorMessage code={status} />;
};

/**
 * The Section component is meant to be used as a top-level Route element,
 * scoping relevant sub-routes components.
 */
export const Section = ({
  enabled = true,
  errorComponent = <SectionError />,
}: SectionProps) => {
  const { pathname } = useLocation();
  const [error, setError] = useState<ErrorResponse>();

  const pageNotFound = <PageNotFound />;

  let fallback: ReactNode;

  if (error) {
    fallback = errorComponent;
  }

  if (!enabled) {
    fallback = pageNotFound;
  }

  // Reset error state when navigating away
  useEffect(() => {
    setError(undefined);
  }, [pathname]);

  return (
    <SectionContext.Provider value={{ error, setError }}>
      <div className="flex h-full">{fallback ?? <Outlet />}</div>
    </SectionContext.Provider>
  );
};
