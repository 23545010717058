import { useCallback } from 'react';
import { ErrorFlag } from '@breeze-ai/ui-library';

import { normalizePolicyResponse } from '../../../network/apis/policies/normalizers';
import { createPolicy } from '../../../network/apis/policies/policies';
import { type PoliciesErrorType } from '../../../network/apis/policies/types';
import { useQuote } from '../../../network/apis/quotes/hooks';
import {
  type BaseErrorType,
  type ErrorResponse,
} from '../../../network/apis/types';
import { useRouteParams } from '../../../router/router-hooks';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { WIZARD_ERROR_MESSAGES, WizardSteps } from '../constants';
import { type PolicyWizardUrlParams, type WizardError } from '../context/types';
import {
  usePolicyWizardContext,
  usePolicyWizardContextAction,
  useSetWizardError,
  useSetWizardStep,
} from './context-hooks';

const getErrorDetails = (
  errorType: PoliciesErrorType | BaseErrorType,
  retry?: () => void,
): WizardError => {
  switch (errorType) {
    case 'ExcludedCargoOwnerAddress':
      return {
        illustration: <ErrorFlag />,
        title: 'Sorry, this cargo owner is located in an excluded territory',
        message: WIZARD_ERROR_MESSAGES.excludedCargoOwnerAddress,
        fallbackStep: WizardSteps.SHIPMENT_INFORMATION,
      };

    default:
    case 'GenericException':
      return {
        message: WIZARD_ERROR_MESSAGES.createPolicy,
        fallbackStep: WizardSteps.SHIPMENT_INFORMATION,
        retry,
      };
  }
};

export const useSubmitPolicyCreationRequest = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyWizardUrlParams>();
  const { data: quote } = useQuote({ quoteId });

  const { forms } = usePolicyWizardContext();
  const setWizardStep = useSetWizardStep();
  const setWizardError = useSetWizardError();
  const dispatch = usePolicyWizardContextAction();

  return useCallback(async () => {
    if (!quote || !forms.data) {
      return;
    }

    setWizardStep(WizardSteps.LOADING);

    try {
      const policy = await createPolicy(
        // TODO: The Quote side of the form uses a different state, so this is
        // a quick fix to make sure externalReference is set when creating the Policy
        // This hook will be removed once we implement a new Policy form.
        { ...forms.data, externalReference: quote.external_reference },
        quote,
      );
      dispatch({
        type: 'set-policy-data',
        payload: normalizePolicyResponse(policy),
      });
      setWizardStep(WizardSteps.SUCCESS);
    } catch (error) {
      reportException(error);
      const { response } = error as ErrorResponse<PoliciesErrorType>;
      const { error_type } = response.data;
      const wizardError = getErrorDetails(error_type);

      setWizardError(wizardError);
    }
  }, [quote, forms.data, setWizardStep, dispatch, setWizardError]);
};
