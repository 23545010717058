import { useLocation } from 'react-router-dom';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import invariant from 'tiny-invariant';

import { useUser } from '../../../../context/auth/auth-hooks';
import { type ConveyanceType } from '../../../../model/Shipment';
import { type Location } from '../../../../network/apis/locations/types';
import {
  type QuoteDuplicationPayload,
  type QuoteSubmissionPayload,
  type QuoteUpdatePayload,
} from '../../../../network/apis/quotes/types';
import { useRouteParams } from '../../../../router/router-hooks';
import { type MapsApiPayload } from '../../../certificates/types';
import {
  isLocation,
  isPlaceLocation,
  isPortLocation,
} from '../../../components/RouteDetails/utils';
import { type PolicyWizardUrlParams } from '../../context/types';
import { type QuoteFormData } from './QuoteForm';
import { isAnyFieldDirty } from './utils';

export const useQuoteFormLogic = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyWizardUrlParams>();
  const { pathname } = useLocation();

  const user = useUser();

  const isDuplicate = pathname.includes('/duplicate/');
  // TODO set route for quote update to /update/:quoteId and check pathname if isUpdate
  const isUpdate = !!quoteId && !isDuplicate;

  const getPayload = (
    form: FormApi<QuoteFormData, typeof zodValidator>,
  ): QuoteSubmissionPayload | QuoteUpdatePayload => {
    const formData = form.state.values;

    // TODO: copied from useFormLogic under CertificateFlowPage;
    // move to shared place
    const getPlacePayload = (
      location?: Location | null,
    ): MapsApiPayload | null | undefined => {
      if (location === undefined) {
        return undefined;
      }
      if (location === null) {
        return null;
      }
      if (location.type === 'place') {
        return {
          place_id: location.place.place_id,
          session_token: location.place.session_token,
        };
      }

      return undefined;
    };

    const loadingPlace = getPlacePayload(formData.placeOfLoading);
    const deliveryPlace = getPlacePayload(formData.placeOfDelivery);
    const originPlace = getPlacePayload(formData.origin);
    const destinationPlace = getPlacePayload(formData.destination);
    const originPortCode =
      formData.origin?.type === 'port' ? formData.origin.port.code : undefined;
    const destinationPortCode =
      formData.destination?.type === 'port'
        ? formData.destination.port.code
        : undefined;

    const getCreationPayload = (): QuoteSubmissionPayload => {
      if (
        !formData.commodityCategory ||
        !formData.cargoOwner ||
        !formData.containerModeId ||
        !formData.coveragePackage
      ) {
        throw new Error('Please fill in all required fields');
      }

      return {
        distributor_id: formData.distributorId,

        transport_mode_code: formData.primaryMot,

        origin_port_code: originPortCode,
        destination_port_code: destinationPortCode,

        origin_place_provider_details: originPlace,
        destination_place_provider_details: destinationPlace,

        loading_place_provider_details: loadingPlace ?? undefined,
        loading_transport_mode_code: formData.placeOfLoading
          ? formData.placeOfLoadingMot
          : undefined,

        delivery_place_provider_details: deliveryPlace ?? undefined,
        delivery_transport_mode_code: formData.placeOfDelivery
          ? formData.placeOfDeliveryMot
          : undefined,

        commodity: {
          type_id: formData.commodityCategory.typeId,
          name: formData.commodityCategory.name,
          description: formData.commodityDescription,
          currency_code: formData.commodityCurrency,
          value: formData.commodityValue
            ? Number(formData.commodityValue)
            : undefined,
        },

        cargo_owner: {
          id: formData.cargoOwner.id,
          name: formData.cargoOwner.company_name,
        },

        container_mode_id: formData.containerModeId,

        freight_cost: formData.freightCost
          ? Number(formData.freightCost)
          : undefined,
        freight_cost_currency: formData.freightCost
          ? formData.freightCostCurrency
          : undefined,

        duty_cost: formData.dutyCost ? Number(formData.dutyCost) : undefined,
        duty_cost_currency: formData.dutyCost
          ? formData.dutyCostCurrency
          : undefined,

        external_reference: formData.externalReference,
        special_conditions: formData.specialConditions?.map((c) => ({
          value: c,
        })),
        coverage_package: formData.coveragePackage,
      };
    };

    const getDuplicationPayload = (): QuoteDuplicationPayload => {
      invariant(quoteId, 'Original Quote ID is required for duplication');
      return {
        ...getCreationPayload(),
        original_quote_id: quoteId,
      };
    };

    // TODO: copied from useFormLogic in the Certificate flow page.
    // Find a common place for this form utility.
    // TODO: should probably support nested values (e.g. commodityCategory)
    const getUpdatePayload = (): QuoteUpdatePayload => {
      invariant(quoteId, 'Certificate ID is required for update');
      invariant(user?.distributor.id, 'Distributor ID is required for update');

      const getDirtyValue = <T>(
        field: keyof QuoteFormData,
      ): T | null | undefined => {
        const value = formData[field];
        const isDirty = form.state.fieldMeta[field]?.isDirty;

        // type narrow the field to Location type
        if (isLocation(value)) {
          if (isPortLocation(value)) {
            return (isDirty ? value.port.code : undefined) as T;
          }

          if (isPlaceLocation(value)) {
            return (isDirty ? getPlacePayload(value) : undefined) as T;
          } else {
            (isDirty ? getPlacePayload(value) : undefined) as T;
          }
        }

        const cleanValue = typeof value === 'string' ? value.trim() : value;

        if (isDirty && (value || value === 0)) {
          return cleanValue as T;
        }

        // The user may delete the content of the field, meaning the value will be empty string.
        // We are sending null in the payload for values that have been cleared.
        if (isDirty && !value) {
          return null;
        }

        return undefined;
      };

      return {
        distributor_id: getDirtyValue('distributorId'),

        transport_mode_code: getDirtyValue<ConveyanceType>('primaryMot'),

        origin_port_code: isPortLocation(formData.origin)
          ? getDirtyValue<string>('origin')
          : undefined,
        destination_port_code: isPortLocation(formData.destination)
          ? getDirtyValue<string>('destination')
          : undefined,

        origin_place_provider_details: isPlaceLocation(formData.origin)
          ? getDirtyValue<MapsApiPayload>('origin')
          : undefined,
        destination_place_provider_details: isPlaceLocation(
          formData.destination,
        )
          ? getDirtyValue<MapsApiPayload>('destination')
          : undefined,

        loading_place_provider_details:
          getDirtyValue<MapsApiPayload>('placeOfLoading'),
        loading_transport_mode_code:
          getDirtyValue<ConveyanceType>('placeOfLoadingMot'),

        delivery_place_provider_details:
          getDirtyValue<MapsApiPayload>('placeOfDelivery'),
        delivery_transport_mode_code:
          getDirtyValue<ConveyanceType>('placeOfDeliveryMot'),

        commodity: isAnyFieldDirty(form, [
          'commodityCategory',
          'commodityDescription',
          'commodityCurrency',
          'commodityValue',
        ])
          ? {
              type_id:
                formData.commodityCategory &&
                form.state.fieldMeta['commodityCategory'].isDirty
                  ? formData.commodityCategory.typeId
                  : undefined,
              name:
                formData.commodityCategory &&
                form.state.fieldMeta['commodityCategory'].isDirty
                  ? formData.commodityCategory.name
                  : undefined,
              description:
                formData.commodityDescription &&
                form.state.fieldMeta['commodityDescription'].isDirty
                  ? formData.commodityDescription
                  : undefined,
              currency_code:
                formData.commodityCurrency &&
                form.state.fieldMeta['commodityCurrency'].isDirty
                  ? formData.commodityCurrency
                  : undefined,
              value:
                formData.commodityValue &&
                form.state.fieldMeta['commodityValue'].isDirty
                  ? Number(formData.commodityValue)
                  : undefined,
            }
          : undefined,

        cargo_owner: isAnyFieldDirty(form, 'cargoOwner')
          ? {
              id:
                formData.cargoOwner &&
                form.state.fieldMeta['cargoOwner'].isDirty
                  ? formData.cargoOwner.id
                  : undefined,
              name:
                formData.cargoOwner &&
                form.state.fieldMeta['cargoOwner'].isDirty
                  ? formData.cargoOwner.company_name
                  : undefined,
            }
          : undefined,

        container_mode_id: getDirtyValue('containerModeId'),

        freight_cost: getDirtyValue('freightCost'),
        freight_cost_currency: getDirtyValue('freightCostCurrency'),

        duty_cost: getDirtyValue('dutyCost'),
        duty_cost_currency: getDirtyValue('dutyCostCurrency'),

        external_reference: getDirtyValue('externalReference'),
        special_conditions:
          formData.specialConditions &&
          form.state.fieldMeta['specialConditions'].isDirty
            ? formData.specialConditions.map((c) => ({
                value: c,
              }))
            : undefined,
        coverage_package: getDirtyValue('coveragePackage'),
      };
    };

    if (isDuplicate) {
      return getDuplicationPayload();
    }

    if (isUpdate) {
      return getUpdatePayload();
    }

    return getCreationPayload();
  };

  return { isDuplicate, isUpdate, getPayload };
};
