import { type ComponentProps } from 'react';
import { Badge, Typography } from '@breeze-ai/ui-library';
import { type IconName } from '@fortawesome/fontawesome-svg-core';

import { type Claim, type ClaimStatus } from '../../../model/Claim';

type BadgeConfig = {
  label: string;
  variant?: Variants.Color;
  icon?: IconName;
};

const badgeVariants: Record<ClaimStatus, BadgeConfig> = {
  unassigned: { label: 'Unassigned', icon: 'circle-notch' },
  FNOL: { label: 'Submitted', variant: 'info', icon: 'check-double' },
  under_evaluation: { label: 'In Review', icon: 'spinner' },
  reserved: { label: 'In Review', icon: 'spinner' },
  settled: { label: 'Paid', variant: 'success', icon: 'check' },
  ex_gratia: { label: 'Paid', variant: 'success', icon: 'check' },
  rejected: {
    label: 'Rejected',
    variant: 'error',
    icon: 'exclamation-triangle',
  },
  withdrawn_by_client: {
    label: 'Withdrawn by Client',
    variant: 'success',
    icon: 'check',
  },
};

type ClaimStatusBadgeProps = Partial<ComponentProps<typeof Badge>> & {
  claim: Claim;
};

export const ClaimStatusBadge = ({
  claim: { status },
  ...props
}: ClaimStatusBadgeProps) => {
  const { label, variant, icon } = badgeVariants[status];

  return (
    <Badge variant={variant} role="claim-status-badge" {...props}>
      <Typography prefixIcon={icon} variant={variant} level="subtext">
        {label}
      </Typography>
    </Badge>
  );
};
