import { type ComponentProps } from 'react';
import { type Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type PremiumOffer } from '../../../model/Quote';
import styles from './PremiumBreakdown.module.scss';
import { PremiumTotal } from './PremiumTotal/PremiumTotal';
import { PriceComponent } from './PriceComponent/PriceComponent';

export type PriceComponentTypographyProps = {
  title?: ComponentProps<typeof Typography>;
  currency?: ComponentProps<typeof Typography>;
};

type PremiumBreakdownProps = {
  entity: PremiumOffer;
  className?: string;
  rowClassname?: string;
  totalRowClassname?: string;
  componentProps?: {
    components?: PriceComponentTypographyProps;
    total?: PriceComponentTypographyProps;
  };
};

export const PremiumBreakdown = ({
  entity,
  className,
  componentProps,
  rowClassname,
  totalRowClassname,
}: PremiumBreakdownProps) => {
  const showExchangeRate = useFeatureToggle('show_currencies_exchange_rates');
  const { premium_value, premium_currency, tax } = entity;

  return (
    <div
      className={classnames(styles.premiumBreakdown, className)}
      role="premium-breakdown"
    >
      <div className={styles.components}>
        <PriceComponent
          title="Premium"
          name="premium"
          value={premium_value}
          currency={premium_currency}
          className={rowClassname}
          typographyProps={componentProps?.components}
        />
        {tax && tax?.value > 0 && (
          <PriceComponent
            title="Taxes"
            name="tax"
            value={tax.value}
            currency={tax.currency_code}
            className={rowClassname}
            typographyProps={componentProps?.components}
          />
        )}
      </div>
      <PremiumTotal
        entity={entity}
        className={totalRowClassname}
        rowClassname={rowClassname}
        typographyProps={componentProps?.total}
        showExchangeRate={showExchangeRate}
      />
    </div>
  );
};
