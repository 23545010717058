import { useCallback, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import {
  Alert,
  FormLayout,
  FormRow,
  Input,
  Typography,
} from '@breeze-ai/ui-library';
import { Button, Logo } from '@breezeai-frontend/cargo-ui';

import { useAuthActions } from '../../../context/auth/auth-hooks';
import { useReCAPTCHA } from '../../../context/recaptcha/ReCaptchaProvider';
import { type LoginCredentials } from '../../../network/apis/auth/auth';
import { type AuthErrorType } from '../../../network/apis/auth/types';
import {
  type BaseErrorType,
  type ErrorResponse,
} from '../../../network/apis/types';
import { RICSignInRedirect } from '../../../router/tradeflow-login/RICSignInRedirect';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { getTheme } from '../../../utils/getTheme';
import { useTracking } from '../../../utils/snowplow/hooks';
import { validateEmail } from '../../../utils/validateEmail';
import { useKeypressEffect } from '../../hooks/use-keypress-effect';
import styles from './SignIn.module.scss';

type SignInFormField = keyof LoginCredentials;

const validators: { [key in SignInFormField]: (value?: string) => boolean } = {
  email: validateEmail,
  password: () => true,
};

const Form = ({
  setAuthenticationError,
}: {
  setAuthenticationError: (error: string | false) => void;
}) => {
  const [userInput, setUserInput] = useState<Partial<LoginCredentials>>();

  const [loading, setLoading] = useState<boolean>();
  const { login } = useAuthActions();
  const { verifyReCAPTCHA } = useReCAPTCHA();

  const onFieldChange = useCallback(
    (field: SignInFormField, value: string) => {
      setUserInput({
        ...userInput,
        [field]: value,
      });
    },
    [userInput],
  );

  const validateForm = useCallback(
    () =>
      userInput &&
      Object.entries(userInput).every(([key, value]) =>
        validators[key as SignInFormField](value),
      ),
    [userInput],
  );

  const setError = useCallback(
    (error: AuthErrorType | BaseErrorType) => {
      switch (error) {
        case 'IncorrectLoginException':
          setAuthenticationError('Incorrect email or password.');
          break;
        default:
          setAuthenticationError(
            'Something went wrong, please refresh and try again.',
          );
          break;
      }
    },
    [setAuthenticationError],
  );

  const onSubmit = useCallback(async () => {
    if (!loading) {
      setAuthenticationError(false);

      if (!userInput || !validateForm()) {
        setAuthenticationError('Some fields are missing or invalid.');
        return;
      }

      setLoading(true);

      try {
        const reCaptchaResult = await verifyReCAPTCHA('platform_sign_in');
        if (reCaptchaResult?.success) {
          await login?.(userInput as LoginCredentials);
        }
      } catch (error) {
        const { response } = error as ErrorResponse<AuthErrorType>;
        const { error_type } = response?.data ?? {};
        reportException(error);
        setError(error_type);
      } finally {
        setLoading(false);
      }
    }
  }, [
    loading,
    userInput,
    validateForm,
    verifyReCAPTCHA,
    login,
    setAuthenticationError,
    setError,
  ]);

  useKeypressEffect('Enter', onSubmit);

  return (
    <FormLayout>
      <FormRow>
        <Input
          autoComplete="username"
          required
          id="email"
          type="email"
          label="Email Address"
          placeholder="example@gmail.com"
          testId="email-input"
          disabled={loading}
          onChange={({ target: { value } }) => onFieldChange('email', value)}
        />
      </FormRow>
      <FormRow>
        <Input
          required
          autoComplete="current-password"
          id="password"
          type="password"
          label="Password"
          placeholder="Password..."
          testId="password-input"
          disabled={loading}
          onChange={({ target: { value } }) => onFieldChange('password', value)}
        />
      </FormRow>
      <FormRow align="end">
        <RouterLink
          to="/login/reset-password"
          className={styles.passwordRecoveryLink}
        >
          <Typography variant="secondary">Forgot your password?</Typography>
        </RouterLink>
      </FormRow>
      <FormRow>
        <Button
          size="large"
          data-testid="submit"
          onPress={onSubmit}
          isLoading={loading}
          loadingText="Loading..."
          label="Sign In"
        />
      </FormRow>
    </FormLayout>
  );
};

function PlatformSignIn() {
  useTracking({
    eventFeature: 'login',
    eventAction: 'page_view',
    eventTrigger: 'sign_in',
  });

  const theme = getTheme();

  const [authenticationError, setAuthenticationError] = useState<
    string | false
  >();

  return (
    <div className={styles.signInPageWrapper} data-testid="sign-in-page">
      <Logo
        variant="dark"
        theme={theme}
        className="self-start w-auto h-30 w-30"
      />
      <div className={styles.body}>
        <div className={styles.content}>
          <Typography level="h2" className={styles.title}>
            Welcome!
          </Typography>
          {authenticationError && (
            <Alert
              variant="error"
              role="error-alert"
              dismissible={true}
              onDismiss={() => setAuthenticationError(false)}
              className={styles.errorAlert}
            >
              {authenticationError}
            </Alert>
          )}
          <Form setAuthenticationError={setAuthenticationError} />
        </div>
      </div>
      <div className={styles.coverImage} />
    </div>
  );
}

export const SignIn = () => {
  const [searchParams] = useSearchParams();
  const gcId = searchParams.get('gcid');

  return import.meta.env.VITE_APP_THEME === 'wtw' && gcId ? (
    <RICSignInRedirect gcId={gcId} />
  ) : (
    <PlatformSignIn />
  );
};
