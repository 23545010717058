import { useRef, useState } from 'react';
import { FaShip, FaTruck } from 'react-icons/fa';
import { FaPlaneUp } from 'react-icons/fa6';
import {
  LocationComboBox,
  type LocationComboBoxProps,
  LocationItem,
} from '@breezeai-frontend/cargo-ui';
import { useDebounce } from '@uidotdev/usehooks';
import { v4 as uuid } from 'uuid';

import { useLocations } from '../../../network/apis/locations/hooks';
import {
  type CustomFilters,
  type Location,
} from '../../../network/apis/locations/types';
import { getPortSearchDisplayValue } from '../../utils/ports';
import { isPortLocation } from './utils';

export interface PortsAndPlacesComboBoxProps
  extends Omit<CustomFilters, 'mot_type'>,
    LocationComboBoxProps<Location> {
  filterByMot: CustomFilters['mot_type'];
}

export type ComboBoxKey = PortsAndPlacesComboBoxProps['selectedKey'];

export const PortsAndPlacesComboBox = ({
  filterByMot: mot_type,
  ...props
}: PortsAndPlacesComboBoxProps) => {
  const session_token = useRef(uuid()).current;

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const { data, isFetching } = useLocations({
    options: {
      useErrorBoundary: true,
      suspense: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      cacheTime: 0,
    },
    params: {
      /**
       * This is a quick way to ensure there is a search hit even when
       * the input value has the format "CODE / IATA - Port Name".
       * There are no API results for search query "CODE / IATA - Port Name",
       * so we are extracting the port code from that string.
       * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split
       *
       * I think a proper way around this would be to implement a better search
       * algorithm in the backend.
       */
      query_text: debouncedQuery.split(' - ')[0].split(' / ')[0].trim(),
      custom_filters: {
        mot_type,
      },
      session_token,
    },
  });

  const getIcon = (location: Location) => {
    if (isPortLocation(location)) {
      return location.port.type === 'marine' ? FaShip : FaPlaneUp;
    }

    return FaTruck;
  };

  return (
    <LocationComboBox
      {...props}
      items={data}
      menuTrigger="focus"
      isFetchingItems={isFetching}
      onInputChange={(value) => {
        props.onInputChange && props.onInputChange(value);
        setQuery(value);
      }}
      allowsCustomValue={true}
      emptyCollectionMessage={
        query ? 'No results' : 'Please type and select an option.'
      }
    >
      {data?.map((location) => {
        const testId = isPortLocation(location)
          ? `${location.port.type}-port-item-${location.port.code}`
          : `place-item-${location.place.description}`;
        const textValue = isPortLocation(location)
          ? getPortSearchDisplayValue(location.port)
          : location.place.description;

        const locationWithSessionToken = isPortLocation(location)
          ? location
          : { ...location, place: { ...location.place, session_token } };

        return (
          <LocationItem
            data-testid={testId}
            key={JSON.stringify(locationWithSessionToken)}
            id={JSON.stringify(locationWithSessionToken)}
            Icon={getIcon(location)}
            textValue={textValue}
          >
            {textValue}
          </LocationItem>
        );
      })}
    </LocationComboBox>
  );
};
