import { Suspense, useContext } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Spinner } from '@breezeai-frontend/cargo-ui';
import * as Sentry from '@sentry/react';

import { useFeatureToggle, useUser } from '../context/auth/auth-hooks';
import { AuthContext } from '../context/auth/AuthContext';
import { CertificateDetailsPage } from '../ui/certificates/CertificateDetailsPage/CertificateDetailsPage';
import { CertificateFlowPage } from '../ui/certificates/CertificateFlowPage/CertificateFlowPage';
import { CertificatesPage } from '../ui/certificates/CertificatesPage/CertificatesPage';
import { ClaimCreationFlow } from '../ui/claims/ClaimCreationFlow/ClaimCreationFlow';
import { ClaimDetailsPage } from '../ui/claims/ClaimDetailsPage/ClaimDetailsPage';
import { ClaimsPage } from '../ui/claims/ClaimsPage';
import { Section } from '../ui/components/Section/Section';
import { CustomersPage } from '../ui/customers/CustomersPage';
import { DTCFlow } from '../ui/dtc-flow/DTCFlow';
import { AnnualPolicyFlow } from '../ui/dtc-flow/flows/AnnualPolicyFlow';
import { PerShipmentFlow } from '../ui/dtc-flow/flows/PerShipmentFlow';
import { TradeCreditInsuranceFlow } from '../ui/dtc-flow/flows/TradeCreditInsuranceFlow';
import { DTCFlowSelection } from '../ui/dtc-flow/shared/DTCFlowSelection/DTCFlowSelection';
import { GuestUserPage } from '../ui/guest-user/GuestUserPage/GuestUserPage';
import { ResetPassword } from '../ui/guest-user/ResetPassword/ResetPassword';
import { SignIn } from '../ui/guest-user/SignIn/SignIn';
import { InsightsPage } from '../ui/insights/InsightsPage';
import { InvoiceDetailsPage } from '../ui/invoices/InvoiceDetailsPage/InvoiceDetailsPage';
import { InvoicesPage } from '../ui/invoices/InvoicesPage';
import { PoliciesPage } from '../ui/policies/PoliciesPage';
import { PolicyDetailsPage } from '../ui/policies/PolicyDetailsPage/PolicyDetailsPage';
import { PolicyWizard } from '../ui/policy-wizard/PolicyWizard';
import { PolicyWizard as PolicyWizardV2 } from '../ui/policy-wizard-v2/PolicyWizard';
import { QuotesPage } from '../ui/quotes/QuotesPage';
import { ShipmentsPage } from '../ui/shipments/ShipmentsPage';
import { useSidebarItems } from '../ui/sidebar/sidebar-hooks';
import { StorageDetailsPage } from '../ui/storage/StorageDetailsPage/StorageDetailsPage';
import { StoragePage } from '../ui/storage/StoragePage/StoragePage';
import { AuthorizedPlatform } from './AuthorizedPlatform/AuthorizedPlatform';
import { NavigateWithRoute } from './NavigateWithRoute';
import { PageNotFound } from './PageNotFound';
import { PlatformErrorBoundary } from './PlatformErrorBoundary';
import { ProtectedRoute } from './ProtectedRoute';
import { STSLoginCallback } from './tradeflow-login/STSLoginCallback';

export const AppRouter = () => {
  const user = useUser();

  // As feature flags are returned via the user/ API, the DTC flow is enabled based on the theme - no user/ API call is made in the DTC flow.
  const enableDTCFlow = import.meta.env.VITE_APP_THEME === 'breeze';
  // These feature flags are returned via the user/ API (via DevCycle)
  const enableInsightsPage = useFeatureToggle('enable_insights_page');
  const enableQuotes = useFeatureToggle('enable_quotes');
  const enableQuotesV2 = useFeatureToggle('enable_quotes_v2');
  const enablePolicies = useFeatureToggle('enable_policies');
  const enableClaims = useFeatureToggle('enable_claims');
  const enableShipmentsPage = useFeatureToggle('enable_shipments_page');
  const enableInvoices = useFeatureToggle('enable_invoices_page');
  const enableCustomersPage = useFeatureToggle('enable_customers_page');
  const enableCertificates = useFeatureToggle('enable_certificates_page');
  const enableStorage = useFeatureToggle('enable_storage');
  const enableDuplicateQuote = useFeatureToggle('enable_duplicate_quote');

  const items = useSidebarItems();
  const landingPage = items.find((item) => !item.hidden);

  // The below logic is to derive the path for the page that the user intended to visit once they login
  const { redirectToOnLogin } = useContext(AuthContext);
  let intendedPath = String(landingPage?.path);
  if (
    redirectToOnLogin &&
    redirectToOnLogin !== '/login' &&
    redirectToOnLogin !== '/'
  ) {
    intendedPath = redirectToOnLogin;
  }

  // See https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<PageNotFound />}>
        {/* Public Routes */}
        <Route
          path="login"
          element={user ? <Navigate to="/" replace /> : <GuestUserPage />}
        >
          <Route index element={<SignIn />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="callback" element={<STSLoginCallback />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>

        {/* Flexport routes (DTC flow) */}
        {enableDTCFlow && (
          <Route path="flexport" element={<DTCFlow />}>
            <Route
              index
              element={<Navigate to="./shipment-insurance" replace />}
            />
            <Route path="shipment-insurance" element={<PerShipmentFlow />} />
            <Route path="start" element={<DTCFlowSelection />} />
            <Route path="annual-policy" element={<AnnualPolicyFlow />} />
            <Route
              path="trade-credit-insurance"
              element={<TradeCreditInsuranceFlow />}
            />
            {/* To be removed when decided no longer necessary to servive url */}
            <Route
              path="continuous-entry-bonds"
              element={<Navigate to="/flexport/start" replace />}
            />
            <Route path="*" element={<Navigate to="/flexport" replace />} />
          </Route>
        )}

        {/* Authenticated Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute authorized={!!user}>
              <AuthorizedPlatform />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to={intendedPath} replace />} />
          <Route
            path="insights"
            element={<Section enabled={enableInsightsPage} />}
          >
            <Route index element={<InsightsPage />} />
          </Route>

          <Route
            path="shipments"
            element={<Section enabled={enableShipmentsPage} />}
          >
            <Route index element={<ShipmentsPage />} />
          </Route>

          <Route
            path="certificates"
            element={<Section enabled={enableCertificates} />}
          >
            <Route index element={<CertificatesPage />} />
            <Route path="create" element={<CertificateFlowPage />} />
            <Route
              path="update/:certificateId/:policyId"
              element={
                <Suspense fallback={<Spinner className="self-center" />}>
                  <CertificateFlowPage />
                </Suspense>
              }
            />
            <Route
              path="duplicate/:certificateId/:policyId"
              element={
                <Suspense fallback={<Spinner className="self-center" />}>
                  <CertificateFlowPage />
                </Suspense>
              }
            />
            {/* TODO: Remove 'details' from path */}
            <Route
              path="details/:certificateId/:policyId"
              element={<CertificateDetailsPage />}
            />
          </Route>

          <Route path="storage" element={<Section enabled={enableStorage} />}>
            <Route index element={<StoragePage />} />
            <Route
              path="create/:step/:storageLocationId?/:policyId?"
              element={<StoragePage />}
            />
            <Route
              path="update/:step/:storageLocationId/:policyId"
              element={<StorageDetailsPage />}
            />
            {/* TODO: Remove 'details' from path */}
            <Route
              path="details/:storageLocationId/:policyId"
              element={<StorageDetailsPage />}
            />
          </Route>

          <Route path="quotes" element={<Section enabled={enableQuotes} />}>
            <Route index element={<QuotesPage />} />
            <Route
              path="create"
              element={
                enableQuotesV2 ? (
                  <PlatformErrorBoundary>
                    <Suspense fallback={<Spinner className="self-center" />}>
                      <PolicyWizardV2 />
                    </Suspense>
                  </PlatformErrorBoundary>
                ) : (
                  <PolicyWizard />
                )
              }
            />
            <Route
              path=":quoteId"
              element={
                enableQuotesV2 ? (
                  <PlatformErrorBoundary>
                    <Suspense fallback={<Spinner className="self-center" />}>
                      <PolicyWizardV2 />
                    </Suspense>
                  </PlatformErrorBoundary>
                ) : (
                  <PolicyWizard />
                )
              }
            />
            <Route
              path="duplicate/:quoteId"
              element={
                enableDuplicateQuote ? (
                  <PlatformErrorBoundary>
                    <Suspense fallback={<Spinner className="self-center" />}>
                      <PolicyWizardV2 />
                    </Suspense>
                  </PlatformErrorBoundary>
                ) : (
                  <Navigate to="/quotes" replace />
                )
              }
            />
          </Route>

          <Route path="policies" element={<Section enabled={enablePolicies} />}>
            <Route index element={<PoliciesPage />} />
            <Route path=":policyId" element={<PolicyDetailsPage />} />
          </Route>

          <Route path="claims" element={<Section enabled={enableClaims} />}>
            <Route index element={<ClaimsPage />} />
            <Route path="start" element={<ClaimCreationFlow />} />
            <Route path=":claimId" element={<ClaimDetailsPage />} />
          </Route>

          <Route path="invoices" element={<Section enabled={enableInvoices} />}>
            <Route index element={<InvoicesPage />} />
            <Route path=":invoiceId" element={<InvoiceDetailsPage />} />
          </Route>

          <Route
            path="customers"
            element={<Section enabled={enableCustomersPage} />}
          >
            <Route index element={<CustomersPage />} />
          </Route>
        </Route>

        {/* Backward compatibility routes */}
        <Route
          path="platform/*"
          element={
            <NavigateWithRoute
              to={({ location: { pathname, search } }) =>
                `/${pathname}${search}`
              }
              replace
            />
          }
        />
        {/* TODO: Can be removed one route is no longer in use by emails */}
        <Route
          path="start"
          element={<Navigate to="/quotes/create" replace />}
        />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};
