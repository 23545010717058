import { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from '@breeze-ai/ui-library';
import { Button, Logo } from '@breezeai-frontend/cargo-ui';
import classnames from 'classnames';

import { useUser } from '../../context/auth/auth-hooks';
import { getTheme } from '../../utils/getTheme';
import { SidebarItem } from './components/SidebarItem/SidebarItem';
import { UserMenu } from './components/UserMenu/UserMenu';
import styles from './Sidebar.module.scss';
import { useActiveSidebarItem, useSidebarItems } from './sidebar-hooks';

const BREAKPOINT = 1024;

export const Sidebar = () => {
  const [expanded, setExpanded] = useState<boolean>(
    window.innerWidth < BREAKPOINT ? false : true,
  );
  const user = useUser();
  const items = useSidebarItems();
  const activeSection = useActiveSidebarItem();

  const THEME = getTheme();

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < BREAKPOINT) {
        setExpanded(false);
      }

      if (window.innerWidth >= BREAKPOINT) {
        setExpanded(true);
      }
    });
  }, []);

  if (!user) {
    return null;
  }

  const organizationLogo = user?.distributor?.display_logo_url;

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      data-testid="sidebar"
      className={classnames(
        styles.sidebarWrapper,
        'bg-navbar-bg border border-navbar-bg-border',
        {
          [styles.expanded]: expanded,
        },
      )}
    >
      <div className={styles.header}>
        <Logo minified={!expanded} variant="white" theme={THEME} />
      </div>
      <div className={styles.body} data-testid="nav-items">
        {items.map((item, i) =>
          item.hidden ? null : (
            <SidebarItem
              {...item}
              key={i}
              minified={!expanded}
              active={activeSection === item.path}
            />
          ),
        )}
      </div>
      <div className={styles.footer}>
        <UserMenu user={user} minified={!expanded} />
        {organizationLogo && (
          <Link
            className={styles.organizationLogoWrapper}
            newTab={true}
            icon={false}
          >
            <img
              src={organizationLogo}
              alt="organization-logo"
              className={styles.organizationLogo}
            />
          </Link>
        )}
      </div>
      <Button
        leftIcon={expanded ? <FaChevronLeft /> : <FaChevronRight />}
        onPress={toggleSidebar}
        width="circle"
        size="xsmall"
        customStyles="z-10 absolute right-[-12px] top-11 rounded-full text-navbar-expand-button-icon hover:text-navbar-expand-button-icon-hover bg-navbar-expand-button-bg-default hover:bg-navbar-expand-button-bg-hover"
      />
    </div>
  );
};
