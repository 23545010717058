import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { PlatformErrorBoundary } from '../../../router/PlatformErrorBoundary';
import { useAppNavigation } from '../../../router/use-app-navigation';
import { useTracking } from '../../../utils/snowplow/hooks';
import { PolicyClaims } from '../../components/PolicyClaims/PolicyClaims';
import { PollingFileList } from '../../components/PollingFileList/PollingFileList';
import { QuoteCoverageDescriptions } from '../../components/QuoteCoverageDescriptions/QuoteCoverageDescriptions';
import { PolicyAdditionalClauses } from './PolicyAdditionalClauses/PolicyAdditionalClauses';
import styles from './PolicyDetailsPage.module.scss';
import { PolicyInsuranceDetails } from './PolicyInsuranceDetails/PolicyInsuranceDetails';
import { PolicyPriceBreakdown } from './PolicyPriceBreakdown/PolicyPriceBreakdown';
import { PolicyShipmentDetails } from './PolicyShipmentDetails/PolicyShipmentDetails';
import Title from './Title/Title';

export type PolicyRouteParams = {
  policyId: string;
};

export const PolicyDetailsPage = () => {
  const enableAdditionalClauses = useFeatureToggle('enable_additional_clauses');
  const enableClaims = useFeatureToggle('enable_claims');

  useTracking();
  const { policyId } = useParams<PolicyRouteParams>();
  const { navigateToSection } = useAppNavigation();

  return (
    <PlatformErrorBoundary
      title="Oops!"
      subtitle="Sorry, the policy you are looking for doesn't exist"
      code={404}
      actions={[
        {
          level: 'secondary',
          children: 'Back to policies',
          onClick: () => navigateToSection('policies'),
        },
      ]}
    >
      <SectionLayout
        data-testid="policy-details-page"
        title={
          <Suspense
            fallback={
              <div
                style={{ width: '300px', height: '60px' }}
                className={styles.skeletonLoader}
              />
            }
          >
            <Title />
          </Suspense>
        }
      >
        <div className={styles.policiesDetailsPage}>
          <div className={styles.contentWrapper}>
            <div className={styles.wrapper}>
              <div className={styles.detailsLayout}>
                <div className={styles.policyDetails}>
                  <Suspense
                    fallback={
                      <div
                        style={{ height: '330px' }}
                        data-testid="skeleton-loader"
                        className={styles.skeletonLoader}
                      />
                    }
                  >
                    <PolicyShipmentDetails />
                  </Suspense>

                  <Suspense
                    fallback={
                      <div
                        style={{
                          height: '230px',
                        }}
                        data-testid="skeleton-loader"
                        className={styles.skeletonLoader}
                      />
                    }
                  >
                    <PolicyInsuranceDetails />
                  </Suspense>
                </div>
                <Suspense
                  fallback={
                    <div
                      style={{
                        flex: 0.2,
                      }}
                      className={styles.skeletonLoader}
                    />
                  }
                >
                  <PolicyPriceBreakdown className={styles.priceDetails} />
                </Suspense>
              </div>

              {enableAdditionalClauses && (
                <Suspense
                  fallback={
                    <div
                      style={{ height: '200px' }}
                      className={styles.skeletonLoader}
                    />
                  }
                >
                  <PolicyAdditionalClauses />
                </Suspense>
              )}

              <Suspense
                fallback={
                  <div
                    style={{ height: '200px' }}
                    className={styles.skeletonLoader}
                  />
                }
              >
                <PollingFileList />
              </Suspense>

              {enableClaims && (
                <Suspense
                  fallback={
                    <div
                      style={{ height: '100px' }}
                      className={styles.skeletonLoader}
                    />
                  }
                >
                  <div className={styles.policyClaims}>
                    <PolicyClaims policyId={policyId} />
                  </div>
                </Suspense>
              )}

              <Suspense
                fallback={
                  <div
                    style={{ height: '100px' }}
                    className={styles.skeletonLoader}
                  />
                }
              >
                <div className="p-6 bg-system-grey-white rounded-3xl">
                  <QuoteCoverageDescriptions policyId={policyId} />
                </div>
              </Suspense>
            </div>
          </div>
        </div>
      </SectionLayout>
    </PlatformErrorBoundary>
  );
};
