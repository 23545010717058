import { tv } from '../../utils/customTv';

export const button = tv({
  base: `gap-2 h-min-8 min-w-8 flex items-center justify-center whitespace-nowrap rounded-radius-button text-center text-sm transition
    focus:outline-none focus-visible:ring-1 focus-visible:ring-field-border-focused focus-visible:ring-opacity-50
    disabled:text-buttons-text-disabled
    cursor-pointer
    disabled:cursor-not-allowed
    `,
  variants: {
    variant: {
      primary: `
        bg-buttons-primary-bg-default
        active:bg-buttons-primary-bg-press
        hover:bg-buttons-primary-bg-hover
        disabled:bg-buttons-primary-bg-disabled
        disabled:hover:bg-buttons-primary-bg-disabled
        text-white`,
      secondary: `bg-buttons-secondary-bg-default
        border
        border-buttons-secondary-border-default
        active:bg-buttons-secondary-bg-pressed
        hover:bg-buttons-secondary-bg-hover
        text-buttons-secondary-text
        disabled:hover:bg-buttons-secondary-bg-disabled
        disabled:border-buttons-secondary-border-disabled
        disabled:text-buttons-text-disabled
        disabled:hover:bg-buttons-secondary-bg-disabled
        `,
      ghost: `bg-transparent
      hover:bg-buttons-ghost-bg-hover
      enabled:active:bg-buttons-ghost-bg-pressed
      text-buttons-ghost-text
      disabled:hover:bg-buttons-ghost-bg-disabled
      disabled:text-buttons-text-disabled
      disabled:bg-buttons-ghost-bg-disabled
      disabled:hover:bg-buttons-ghost-bg-disabled
      `,
      error: `bg-system-red-500 hover:bg-system-red-600
      active:bg-system-red-900
      text-white
      disabled:bg-system-gray-200
      disabled:hover:bg-system-gray-200
      disabled:text-buttons-text-disabled
      `,
      warning: `bg-system-orange-500
      hover:bg-system-orange-600
      active:bg-system-orange-900
      text-white
      disabled:bg-system-gray-200
      disabled:hover:bg-system-gray-200
      disabled:text-buttons-text-disabled
      `,
      success: `bg-system-green-500
      hover:bg-system-green-600
      active:bg-system-green-900
      text-white
      disabled:bg-system-gray-200
      disabled:hover:bg-system-gray-200
      disabled:text-buttons-text-disabled`,
      icon: `bg-transparent p-0 min-w-0`,
    },
    size: {
      xsmall: ['h-6 min-w-6'],
      small: ['h-8'],
      medium: ['h-9'],
      large: ['h-10'],
    },
    width: {
      fixed: ['w-28 px-4 py-1'],
      auto: ['w-auto px-4 py-1'],
      full: ['w-full px-4 py-1'],
      square: ['w-auto rounded-xl gap-0'],
      circle: ['w-auto gap-0'],
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'small',
  },
});
